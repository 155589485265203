const Thessaloniki = '/thessaloniki';
const Rome = '/rome';
const Heraklion = '/heraklion';
const PavlosMelas = '/pavlosmelas';
const Civitavecchia = '/civitavecchia';
const ThessalonikiDashboard = '/thessalonikidashboard';
const RomeDashboard = '/romedashboard';
const HeraklionDashboard = '/herakliondashboard';
const PavlosMelasDashboard = '/pavlosmelasdashboard';
const CivitavecchiaDashboard = '/civitavecchiadashboard';
const Metadata = '/metadata';
const TermsOfUse = '/termsofuse';
const PrivacyPolicy = '/privacypolicy';

const ThessalonikiMobileMap = '/thessalonikimobilemap';
const RomeMobileMap = '/romemobilemap';
const HeraklionMobileMap = '/heraklionmobilemap';

export const StaticRoutes = {
	Civitavecchia, CivitavecchiaDashboard, Thessaloniki, ThessalonikiDashboard, ThessalonikiMobileMap, Rome, RomeDashboard, RomeMobileMap, Heraklion, HeraklionDashboard, PavlosMelas, PavlosMelasDashboard, HeraklionMobileMap, Metadata, TermsOfUse, PrivacyPolicy
};

export const routes = [
	{
		url: Thessaloniki,
		name: 'thessaloniki',
		component: 'Thessaloniki'
	},
	{
		url: ThessalonikiDashboard,
		name: 'dashboard',
		component: 'ThessalonikiDashboard'
	},
	{
		url: ThessalonikiMobileMap,
		name: 'thessalonikimobilemap',
		component: 'ThessalonikiMobileMap'
	},
	{
		url: Rome,
		name: 'rome',
		component: 'Rome'
	},
	{
		url: RomeDashboard,
		name: 'dashboard',
		component: 'RomeDashboard'
	},
	{
		url: RomeMobileMap,
		name: 'romemobilemap',
		component: 'RomeMobileMap'
	},
	{
		url: Heraklion,
		name: 'heraklion',
		component: 'Heraklion'
	},
	{
		url: HeraklionDashboard,
		name: 'dashboard',
		component: 'HeraklionDashboard'
	},
	
	{
		url: PavlosMelas,
		name: 'pavlosmelas',
		component: 'PavlosMelas'
	},
	{
		url: PavlosMelasDashboard,
		name: 'dashboard',
		component: 'PavlosMelasDashboard'
	},
	
	{
		url: Civitavecchia,
		name: 'civitavecchia',
		component: 'Civitavecchia'
	},
	{
		url: CivitavecchiaDashboard,
		name: 'dashboard',
		component: 'CivitavecchiaDashboard'
	},
	{
		url: HeraklionMobileMap,
		name: 'heraklionmobilemap',
		component: 'HeraklionMobileMap'
	},
	{
		url: Metadata,
		name: 'metadata',
		component: 'Metadata'
	},
	{
		url: TermsOfUse,
		name: 'termsofuse',
		component: 'TermsOfUse'
	},
	{
		url: PrivacyPolicy,
		name: 'privacypolicy',
		component: 'PrivacyPolicy'
	},
];

const combined = {StaticRoutes, routes};

export default combined;
