import React, {Component} from 'react';
import {
    Container
} from 'reactstrap';

import Dashboard from '../../components/dashboard';

import './css/map.css'
import ReactGA from "react-ga";


class PavlosMelasDashboard extends Component {

    componentDidMount() {
        ReactGA.pageview('/pavlosmelasdashboard');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        ReactGA.pageview('/pavlosmelasdashboard');
    }

    render() {

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Dashboard
                        city={'pavlosmelas'}
                    />
                </Container>
            </div>
        );
    }
}

export default PavlosMelasDashboard;
