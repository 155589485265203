import React, { Component } from 'react';
import ReactLoading from 'react-loading-components';

class Loading extends Component {
	render() {
		return (
			<ReactLoading type="puff" witdh={100} height={100} fill="#20a8d8"/>
		);
	}
}

export default Loading;
