import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container
} from 'reactstrap';
import ReactGA from "react-ga";


class PrivacyPolicy extends Component {

    componentDidMount() {
        ReactGA.pageview('/PrivacyPolicy');
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 20)
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/PrivacyPolicy');
    // }

    render() {
        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <div className="post-wrap">
                        <h1 className="entry-title">Privacy Policy</h1>
                        <div className="post-content entry-content">
                            <p><strong>lifeasti.eu Privacy Policy </strong></p>
                            <p>The LIFE ASTI-project (hereafter the “Project”, “we” and “us”) provides this Privacy
                                Policy (hereafter the “Policy”), in order to explain to lifeasti.eu website visitors the
                                policies we use for collecting, sharing, disclosing, using and protecting personal
                                information that becomes available to us through the use of the website (hereafter the
                                “Website” or “Service”) by its visitors. The terms “you”, “your”, “yours” or “visitor”
                                refers to any user or visitor of the Website.</p>
                            <p>The personal data we collect is used for providing and improving the Service. We will not
                                use personal data for any other reason and we will not disclose or share it with any
                                third party except as described in this Privacy Policy. Use of the Website constitutes
                                acceptance on your part, without reservation, of the collection and use of personal data
                                in accordance with this policy.</p>
                            <p>This Policy applies only to personal information collected via the Website and does not
                                apply to data collected by the Project through other ways or via any other websites
                                linked to the Website.</p>
                            <p>The terms used in this Privacy Policy have the same meanings as in our Terms of Use
                                Policy, which is accessible at&nbsp;the Website, unless otherwise defined here.</p>
                            <p><strong>Personal Data Collection and Use</strong></p>
                            <p>In order to improve your experience while using the Service, we may ask you to provide us
                                with personal information, including but not limited to your name and/or your email
                                address. The information we collect in this way may be used to contact or identify you
                                in accordance with the terms of this Policy.</p>
                            <p><strong>Use of Personal Data for Direct Marketing Operations</strong></p>
                            <p>The Project reserves the right to communicate its subscription services, events and
                                educational and training programs to users. If a user wishes at any time to terminate
                                such communications, he may send an email to&nbsp;the address contact@lifeasti.eu.</p>
                            <p><strong>Newsletters</strong></p>
                            <p>The Project reserves the right to send you news updates and/or newsletters at the email
                                address you provide to the Website. These news updates are made for informational and
                                promotional purposes and in order to keep you posted about LIFE ASTI news, activities
                                and events that we, believe may be of your interest.</p>
                            <p>You may exclude yourself from such informational and promotional communications by
                                clicking “Unsubscribe” in the relevant emails or by sending us an email to&nbsp;the
                                address <a href="mailto:contact@lifeasti.eu">contact@lifeasti.eu</a>. Such exclusion
                                will take effect after the time required to complete the relevant process.</p>
                            <p><strong>Data Privacy &amp; Compliance</strong></p>
                            <p>The Project will not disclose personal information to third parties without your consent,
                                except as otherwise provided in this Policy or if requested by a competent judicial
                                authority.</p>
                            <p>Personal data collected by the Website are processed and managed in accordance with all
                                applicable laws and regulations concerning personal data privacy, including but not
                                limited to the GDPR.</p>
                            <p>By providing personal data to the Project, the users of the Website declare their
                                explicit consent for the processing of these data by the Project, through the ways
                                described in this Policy.</p>
                            <p>Users have the right to update, modify, delete and request a copy of their personal data
                                that are kept by the Project. If a user wants to update, modify, delete or receive a
                                copy of his/her personal data, he/she may send an e-mail to&nbsp;<a
                                    href="mailto:contact@lifeasti.eu">contact@lifeasti.eu</a>. Updates, modifications or
                                deletions of personal data will take effect after the time required to complete the
                                relevant process.</p>
                            <p><strong>Log Data</strong></p>
                            <p>Whenever you visit the Website, some information is automatically sent by your browser in
                                the form of Log Data. Log Data may include information such as your IP address, browser
                                version, the pages of our Website that you visit, the time and date of your visit, the
                                time spent on those pages, and other statistics that we may collect.</p>
                            <p><strong>Cookies</strong></p>
                            <p>Cookies are small files of data used as anonymous unique identifiers. They are sent to
                                your browser by the Website and are stored on your computer’s hard drive.</p>
                            <p>The Website uses cookies to collect information and improve your Website visit
                                experience. You have the option to accept or refuse cookies. If you choose to refuse
                                cookies, you may not be able to use some Website services.</p>
                            <p><strong>Links to Other Sites</strong></p>
                            <p>The Website may contain links to other sites. Such sites are not operated by us and we
                                therefore strongly advise you to review their Privacy Policy before using them. We have
                                no control over and assume no responsibility for the privacy policies, practices and
                                content of third-party sites or services.</p>
                            <p><strong>Service Providers</strong></p>
                            <p>We may employ third-party companies to provide the Service on our behalf, to assist us in
                                analyzing how the Service is used and/or to perform various related services. While
                                performing the tasks assigned to them, these companies may have access to personal
                                data.</p>
                            <p>In such cases we are the Data Controller and the third-party companies are the Data
                                Processors. Data Processors are contractually bound to not disclose or use any personal
                                data for any reason other than for what they are intended and to abide to all applicable
                                laws and regulations concerning personal data privacy, including but not limited to the
                                GDPR.</p>
                            <p><strong>Security</strong></p>
                            <p>The Project adopts and uses internationally accepted security practices, protocols,
                                tools, techniques and technologies, including encryption, in order to keep your personal
                                data safe and protected from any type of unauthorized access.</p>
                            <p>Nevertheless, you must be aware that no method of data storage or of data transmission
                                over the Internet can be 100% secure and reliable and therefore we cannot guarantee
                                absolute security of the data kept by us.</p>
                            <p>You must also be aware that the Project has no control over the security measures of
                                other websites you may visit or interact with in any way through the Website.</p>
                            <p><strong>Children’s Personal Information</strong></p>
                            <p>The Website does not address children under the age of 13. We do not knowingly collect
                                personally identifiable information from children under 13. If we become aware that a
                                child under 13 has provided us with personal information, we will take corrective action
                                and delete such information. If you are a parent or guardian and you are aware that your
                                child has provided us with personal information, please contact us so that we take
                                corrective action.</p>
                            <p><strong>Privacy Policy Updates</strong></p>
                            <p>We may update our Privacy Policy from time to time as we deem appropriate. Revisions and
                                updates may refer to changes in our practices, the launch of new services, or changes
                                required to comply with legal requirements. We thus advise you to periodically review
                                this Privacy Policy for any changes.</p>
                            <p>Revised Privacy Policies will apply to existing and future data from the date indicated
                                each time in the Policy. Continued use of the Website constitutes acceptance on your
                                part, without reservation, of the changes.</p>
                            <p><strong>Contact Us</strong></p>
                            <p>If you have any questions, comments or complaints regarding this Privacy Policy or its
                                violation thereof, you may contact us through the contact form <a
                                    href="https://lifeasti.eu/contact/">https://lifeasti.eu/contact/</a>, or by sending
                                us an email directly to:</p>
                            <p><a href="mailto:contact@lifeasti.eu">contact@lifeasti.eu</a>.</p>
                            <p><strong>Effective Date</strong></p>
                            <p>This Privacy Policy is effective as of 25 May 2018.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

PrivacyPolicy = connect(mapStateToProps)(PrivacyPolicy);

export default PrivacyPolicy;
