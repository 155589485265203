import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import TheMapMobile from '../../components/mapMobile';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'


class ThessalonikiMobileMap extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Aerial'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSRelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[22.6711830, 40.3061830], [23.3042702, 40.7892294]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[22.6711830, 40.3061830], [23.3042702, 40.7892294]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSTemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_thess','LIFEASTI:temp_spring_thess','LIFEASTI:temp_summer_thess','LIFEASTI:temp_autumn_thess','LIFEASTI:temp_winter_thess'],
                legend: ['img/legends/temp_thessaloniki_annual.png','img/legends/temp_thessaloniki_spring.png','img/legends/temp_thessaloniki_summer.png','img/legends/temp_thessaloniki_autumn.png','img/legends/temp_thessaloniki_winter.png'],
                visible: true,
                extent: [[22.6711830, 40.3061830], [23.3042702, 40.7892294]],
                opacity: 0.6,
                attributeTable: true
            }
        ];

        return (

            <TheMapMobile
                projection={"EPSG:3857"}
                center={[22.9485878, 40.6372534]}
                zoom={13}
                minZoom={10}
                maxZoom={18}
                extent={[[22.6711830, 40.3061830], [23.3042702, 40.7892294]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n
});

ThessalonikiMobileMap = connect(mapStateToProps)(ThessalonikiMobileMap);

export default ThessalonikiMobileMap;
