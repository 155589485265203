import { Control } from 'ol/control';
import './swipe.css';
import SwipeControl from 'ol-ext/control/Swipe';
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS";
import moment from 'moment';
import Map from "ol/Map";
import { defaults as defaultControls } from "ol/control/util";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import GroupLayer from "ol/layer/Group";
import BingMaps from "ol/source/BingMaps";

let ol_custom_swipe = (function (Control) {

    let options;

    let element;

    let enableButton;

    let disableButton;

    let compareButton;

    let selection_layer_1;

    let selection_layer_2;

    let swipe_layer_date_1;

    let swipe_layer_time_1;

    let swipe_layer_date_2;

    let swipe_layer_time_2;

    let map = null;

    let swipe_selection_layers_div;

    let swipe_map_div;

    let ly1 = null;

    let ly2 = null;

    let layer_new_1 = null;

    let layer_new_2 = null;

    let ctrl = new SwipeControl();

    function SwipeCustomControl(opt_options) {
        options = opt_options || {};


        enableButton = document.createElement('button');
        enableButton.title = options['CompareLayers'];
        enableButton.innerHTML = '<div style="font-size: 14px;">S</div>';

        disableButton = document.createElement('button');
        disableButton.title = options['CompareLayers'];
        disableButton.innerHTML = '<div style="font-size: 14px;">X</div>';
        disableButton.style.display = "none";

        swipe_layer_date_1 = document.createElement("select");
        swipe_layer_date_1.setAttribute("id", "swipe_layer_date_1");

        swipe_layer_time_1 = document.createElement("select");
        swipe_layer_time_1.setAttribute("id", "swipe_layer_time_1");

        swipe_layer_date_2 = document.createElement("select");
        swipe_layer_date_2.setAttribute("id", "swipe_layer_date_2");

        swipe_layer_time_2 = document.createElement("select");
        swipe_layer_time_2.setAttribute("id", "swipe_layer_time_2");

        swipe_selection_layers_div = document.createElement('div');
        swipe_selection_layers_div.setAttribute("id", "swipe_selection_layers_div");
        swipe_selection_layers_div.style.display = "none";

        let swipe_selection_layers_div_layer_left = document.createElement('div');
        swipe_selection_layers_div_layer_left.setAttribute("id", "swipe_selection_layers_div_layer_left");

        let swipe_selection_layers_div_layer_right = document.createElement('div');
        swipe_selection_layers_div_layer_right.setAttribute("id", "swipe_selection_layers_div_layer_right");

        selection_layer_1 = document.createElement("select");
        selection_layer_1.setAttribute("id", "swipe_selection_layer_1");

        selection_layer_2 = document.createElement("select");
        selection_layer_2.setAttribute("id", "swipe_selection_layer_2");

        compareButton = document.createElement('button');
        compareButton.setAttribute("id", "swipe_compare_button");
        compareButton.innerHTML = options['CompareLayers'];

        let swipe_selection_layers_label = document.createElement("LABEL");
        swipe_selection_layers_label.setAttribute("id", "wipe_selection_layers_label");
        swipe_selection_layers_label.innerText = 'Select Layer';

        let swipe_selection_date_label = document.createElement("LABEL");
        swipe_selection_date_label.setAttribute("id", "wipe_selection_date_label");
        swipe_selection_date_label.innerText = 'Select Date-Hour';

        swipe_map_div = document.createElement('div');
        swipe_map_div.setAttribute("id", "swipe_map_div");

        // swipe_selection_layers_div.appendChild(swipe_selection_layers_label);
        // swipe_selection_layers_div.appendChild(swipe_selection_date_label);
        swipe_selection_layers_div_layer_left.appendChild(selection_layer_1);
        swipe_selection_layers_div_layer_left.appendChild(swipe_layer_date_1);
        swipe_selection_layers_div_layer_left.appendChild(swipe_layer_time_1);
        swipe_selection_layers_div_layer_right.appendChild(selection_layer_2);
        swipe_selection_layers_div_layer_right.appendChild(swipe_layer_date_2);
        swipe_selection_layers_div_layer_right.appendChild(swipe_layer_time_2);
        swipe_selection_layers_div.appendChild(swipe_selection_layers_div_layer_left);
        swipe_selection_layers_div.appendChild(swipe_selection_layers_div_layer_right);
        swipe_selection_layers_div.appendChild(compareButton);

        element = document.createElement('div');
        element.className = 'ol-custom-swipe ol-unselectable ol-control';
        element.appendChild(enableButton);
        element.appendChild(disableButton);
        element.appendChild(swipe_selection_layers_div);
        element.appendChild(swipe_map_div);

        Control.call(this, {
            element: element,
            target: options.target
        });

        enableButton.addEventListener('click', this.enableSwipe.bind(this), false);
        disableButton.addEventListener('click', this.disableSwipe.bind(this), false);
        compareButton.addEventListener('click', this.compareSwipe.bind(this), false);
    }

    if (Control) SwipeCustomControl.__proto__ = Control;
    SwipeCustomControl.prototype = Object.create(Control && Control.prototype);
    SwipeCustomControl.prototype.constructor = SwipeCustomControl;

    SwipeCustomControl.prototype.enableSwipe = function doSwipe() {

        enableButton.style.display = "none";
        disableButton.style.display = "block";
        swipe_selection_layers_div.style.display = "block";
        swipe_map_div.style.display = "block";
        swipe_map_div.style.width = "100%";
        swipe_map_div.style.height = "calc(100vh - 220px)";
        document.getElementsByClassName('ol-custom-swipe')[0].style.bottom = "1em";
        document.getElementsByClassName('ol-custom-swipe')[0].style.right = "0.5em";

        const map0 = this.getMap();

        let layers = [];

        map0.getLayers().getArray().forEach(function (ly) {
            try {
                if (ly.get('swipe')) {
                    layers.push(ly)
                }
            } catch (e) {

            }
            try {
                ly.getLayers().getArray().forEach(function (bly) {
                    if (bly.get('swipe')) {
                        layers.push(bly)
                    }
                })
            } catch (e) {

            }

        });

        layers.forEach(function (item, i) {
            if (item.values_.id === "THESSTAPP") {
                layers.splice(i, 1);
                layers.unshift(item);
            }
            if (item.values_.id === "HERAKLTAPP") {
                layers.splice(i, 1);
                layers.unshift(item);
            }
            if (item.values_.id === "ROMETAPP") {
                layers.splice(i, 1);
                layers.unshift(item);
            }
        });

        document.getElementById("swipe_selection_layer_1").innerHTML = "";
        document.getElementById("swipe_selection_layer_2").innerHTML = "";
        layers.forEach(function (ly) {
            let newListData1 = new Option(options[ly.get('title')], ly.get('id'));
            let newListData2 = new Option(options[ly.get('title')], ly.get('id'));
            selection_layer_1.appendChild(newListData1);
            selection_layer_2.appendChild(newListData2);
        });

        let dates = [
            {
                value: moment().format('YYYY-MM-DD').toString(),
                label: moment().format('DD/MM/YYYY').toString()
            },
            {
                value: moment().add('days', 1).format('YYYY-MM-DD').toString(),
                label: moment().add('days', 1).format('DD/MM/YYYY').toString()
            },
            {
                value: moment().add('days', 2).format('YYYY-MM-DD').toString(),
                label: moment().add('days', 2).format('DD/MM/YYYY').toString()
            },
            {
                value: moment().add('days', 3).format('YYYY-MM-DD').toString(),
                label: moment().add('days', 3).format('DD/MM/YYYY').toString()
            },
        ];

        document.getElementById("swipe_layer_date_1").innerHTML = "";
        document.getElementById("swipe_layer_date_2").innerHTML = "";
        dates.forEach(function (ly) {
            let newListData1 = new Option(ly.label, ly.value);
            let newListData2 = new Option(ly.label, ly.value);
            swipe_layer_date_1.appendChild(newListData1);
            swipe_layer_date_2.appendChild(newListData2);
        });

        document.getElementById("swipe_layer_time_1").innerHTML = "";
        document.getElementById("swipe_layer_time_2").innerHTML = "";
        for (let i = 1; i <= 24; i++) {
            let newListData1 = new Option(i.toString(), i.toString());
            let newListData2 = new Option(i.toString(), i.toString());
            swipe_layer_time_1.appendChild(newListData1);
            swipe_layer_time_2.appendChild(newListData2);
        }

        document.getElementById("swipe_map_div").innerHTML = "";
        map = new Map({
            layers: new GroupLayer({
                title: 'Basemaps',
                swipe: false,
                openInLayerSwitcher: false,
                baseLayer: false,
                attributeTable: false,
                layers: [
                    new TileLayer({
                        id: 'Aerial',
                        title: 'Aerial',
                        baseLayer: true,
                        attributeTable: false,
                        swipe: false,
                        visible: true,
                        source: new BingMaps({
                            key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                            imagerySet: 'AerialWithLabels'
                        })
                    }),
                    new TileLayer({
                        id: 'Road',
                        title: 'Road',
                        baseLayer: true,
                        swipe: false,
                        attributeTable: false,
                        visible: false,
                        source: new BingMaps({
                            key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                            imagerySet: 'Road'
                        })
                    })
                ]
            }),
            controls: defaultControls({
                attribution: false,
                zoom: false
            }),
            target: 'swipe_map_div',
            view: new View({
                projection: "EPSG:3857",
                center: map0.getView().getCenter(),
                zoom: map0.getView().getZoom(),
                minZoom: map0.getView().getMinZoom(),
                maxZoom: map0.getView().getMaxZoom(),
                extent: map0.getView().calculateExtent()
            })
        });

        map.addControl(ctrl);

    };

    SwipeCustomControl.prototype.disableSwipe = function stopSwipe() {

        enableButton.style.display = "block";
        disableButton.style.display = "none";
        swipe_selection_layers_div.style.display = "none";
        swipe_map_div.style.display = "none";
        // console.log(document.getElementsByClassName('ol-custom-swipe'));
        document.getElementsByClassName('ol-custom-swipe')[0].style.bottom = "unset";
        document.getElementsByClassName('ol-custom-swipe')[0].style.right = "unset";


        // const map = this.getMap();
        ctrl.removeLayer(layer_new_1);
        ctrl.removeLayer(layer_new_1, true);
        // map.removeControl(ctrl);
        map.removeLayer(layer_new_1);
        map.removeLayer(layer_new_2);

    };

    SwipeCustomControl.prototype.compareSwipe = function doCompareSwipe() {
        let e1 = document.getElementById("swipe_selection_layer_1");
        let e2 = document.getElementById("swipe_selection_layer_2");

        let e1_1 = document.getElementById('swipe_layer_date_1');
        let e2_1 = document.getElementById('swipe_layer_date_2');

        let e1_2 = document.getElementById('swipe_layer_time_1');
        let e2_2 = document.getElementById('swipe_layer_time_2');

        let id1 = e1.options[e1.selectedIndex].value;
        let id2 = e2.options[e2.selectedIndex].value;

        let id1_date = e1_1.value;
        let id2_date = e2_1.value;

        let id1_time = e1_2.value;
        let id2_time = e2_2.value;

        let layer_date_time_1 = moment(id1_date + ' ' + id1_time + ':00:00').utc().format();
        let layer_date_time_2 = moment(id2_date + ' ' + id2_time + ':00:00').utc().format();

        // let map = this.getMap();

        try {
            ctrl.removeLayer(layer_new_1);
            ctrl.removeLayer(layer_new_2, true);
            map.removeLayer(layer_new_1);
            map.removeLayer(layer_new_2);
        } catch (e) {

        }

        const map0 = this.getMap();

        map0.getLayers().getArray().forEach(function (ly) {
            try {
                if (ly.get('id') === id1) {
                    ly1 = ly
                }
                if (ly.get('id') === id2) {
                    ly2 = ly
                }
            } catch (e) {

            }
            try {
                ly.getLayers().getArray().forEach(function (bly) {
                    if (bly.get('id') === id1) {
                        ly1 = bly
                    }
                    if (bly.get('id') === id2) {
                        ly2 = bly
                    }
                })
            } catch (e) {

            }
        });

        layer_new_1 = new ImageLayer({
            id: 'swipe_layer_1',
            title: 'Left Swipe Layer',
            baseLayer: false,
            swipe: false,
            visible: true,
            styles: ly1.get('styles'),
            extent: ly1.get('extent'),
            source: new ImageWMS({
                url: ly1.getSource().url_,
                params: {
                    LAYERS: ly1.getSource().getParams().LAYERS,
                    TILED: false,
                    TIME: layer_date_time_1
                },
                serverType: 'geoserver',
                transition: 0
            }),
            opacity: 0.7,
            attributeTable: false
        });

        layer_new_2 = new ImageLayer({
            id: 'swipe_layer_2',
            title: 'Right Swipe Layer',
            baseLayer: false,
            swipe: false,
            visible: true,
            styles: ly2.get('styles'),
            extent: ly2.get('extent'),
            source: new ImageWMS({
                url: ly2.getSource().url_,
                params: {
                    LAYERS: ly2.getSource().getParams().LAYERS,
                    TILED: false,
                    TIME: layer_date_time_2
                },
                serverType: 'geoserver',
                transition: 0
            }),
            opacity: 0.7,
            attributeTable: false
        });

        map.addLayer(layer_new_2);
        map.addLayer(layer_new_1);

        ctrl.addLayer(layer_new_1);
        ctrl.addLayer(layer_new_2, true);
    };

    return SwipeCustomControl;
}(Control));

export default ol_custom_swipe
