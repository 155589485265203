import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
    {
        name: 'rome',
        role: roles.GUEST,
        children: [
            {
                name: 'dashboard2',
                url: StaticRoutes.RomeDashboard,
                role: roles.GUEST
            },
            {
                name: 'map',
                url: StaticRoutes.Rome,
                role: roles.GUEST
            }
        ]
    },
    {
        name: 'thessaloniki',
        role: roles.GUEST,
        children: [
            {
                name: 'dashboard2',
                url: StaticRoutes.ThessalonikiDashboard,
                role: roles.GUEST
            },
            {
                name: 'map',
                url: StaticRoutes.Thessaloniki,
                role: roles.GUEST
            },
        ]
    },
    {
        name: 'heraklion',
        role: roles.GUEST,
        children: [
            {
                name: 'dashboard2',
                url: StaticRoutes.HeraklionDashboard,
                role: roles.GUEST
            },
            {
                name: 'map',
                url: StaticRoutes.Heraklion,
                role: roles.GUEST
            }
        ]
    },
    {
        name: 'pavlosmelas',
        role: roles.GUEST,
        children: [
            {
                name: 'dashboard2',
                url: StaticRoutes.PavlosMelasDashboard,
                role: roles.GUEST
            },
            {
                name: 'map',
                url: StaticRoutes.PavlosMelas,
                role: roles.GUEST
            }
        ]
    },
    {
        name: 'civitavecchia',
        role: roles.GUEST,
        children: [
            {
                name: 'dashboard2',
                url: StaticRoutes.CivitavecchiaDashboard,
                role: roles.GUEST
            },
            {
                name: 'map',
                url: StaticRoutes.Civitavecchia,
                role: roles.GUEST
            }
        ]
    },
    {
        name: 'metadata',
        url: StaticRoutes.Metadata,
        role: roles.GUEST
    }
];

export default menu;
