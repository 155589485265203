import { connect } from 'react-redux';
import { IntlProvider as ReactIntlProvider, addLocaleData } from 'react-intl';

import config from '../../../../package.json';
import { changeLocale as setLocale } from '../ducks/i18n';

const { locales } = config;
locales.forEach(locale => {
	addLocaleData(require(`react-intl/locale-data/${locale}`));
});

class IntlProvider extends ReactIntlProvider {

	componentDidMount() {

		const userLang = navigator.language;
		let index = 0;
		if(userLang.includes('el') ){
			index = 1;
		}else if(userLang.includes('it')){
			index = 2;
		}else{
			index = 0;
		}

		this.props.dispatch(
			setLocale(locales[index])
		);
	}
}

const mapStateToProps = (state) => ({
	locale: state.i18n.locale !== '' ? state.i18n.locale : locales[0],
	messages: state.i18n.messages,
});

export default connect(mapStateToProps)(IntlProvider);
