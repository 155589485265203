import { combineReducers } from 'redux';

import * as reducers from 'core/ducks';
import { reducers as moduleReducers } from 'modules';
import * as uiReducers from 'core/ducks/ui';
import config from '../package.json';

const { multilingual, locales, modules } = config;

const rootReducer = combineReducers({
	...reducers,
	...moduleReducers,
	ui: combineReducers({
		...uiReducers,
		multilingual: (state=multilingual) => state,
		locales: (state=locales) => state,
	}),
	modules: (state=modules) => state,
});

export default rootReducer;