import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    TabContent,
    Nav,
    NavItem,
    NavLink,
    Label,
    Input
} from 'reactstrap';
import classnames from 'classnames';
import DashboardTabPane from './dashboardTabPane/dashboardTabPane';
import _ from 'lodash';
import T from 'modules/i18n';
import {Api} from "core/api";
import moment from 'moment';

import thess_a from './img/thessaloniki_region_a.png';
import thess_b from './img/thessaloniki_region_b.png';
import thess_c from './img/thessaloniki_region_c.png';
import thess_d from './img/thessaloniki_region_d.png';
import thess_e from './img/thessaloniki_region_e.png';
import thess_f from './img/thessaloniki_region_f.png';
import thess_a_en from './img/thessaloniki_region_a_en.png';
import thess_b_en from './img/thessaloniki_region_b_en.png';
import thess_c_en from './img/thessaloniki_region_c_en.png';
import thess_d_en from './img/thessaloniki_region_d_en.png';
import thess_e_en from './img/thessaloniki_region_e_en.png';
import thess_f_en from './img/thessaloniki_region_f_en.png';

import Distretto_1 from './img/rome_distretto_1.png';
import Distretto_2 from './img/rome_distretto_2.png';
import Distretto_3 from './img/rome_distretto_3.png';
import Distretto_4 from './img/rome_distretto_4.png';
import Distretto_5 from './img/rome_distretto_5.png';
import Distretto_6 from './img/rome_distretto_6.png';
import Distretto_7 from './img/rome_distretto_7.png';
import Distretto_8 from './img/rome_distretto_8.png';
import Distretto_9 from './img/rome_distretto_9.png';
import Municipio_X from './img/rome_municipio_x.png';
import Municipio_XI from './img/rome_municipio_xi.png';
import Municipio_XII from './img/rome_municipio_xii.png';
import Distretto_13 from './img/rome_distretto_13.png';
import Distretto_14 from './img/rome_distretto_14.png';
import Distretto_15 from './img/rome_distretto_15.png';

import heraklion_1 from './img/heraklion_1.png';
import heraklion_2 from './img/heraklion_2.png';
import heraklion_3 from './img/heraklion_3.png';
import heraklion_1_en from './img/heraklion_1_en.png';
import heraklion_2_en from './img/heraklion_2_en.png';
import heraklion_3_en from './img/heraklion_3_en.png';


import pavlosmelas_1 from './img/pavlosmelas_1.png';
import pavlosmelas_1_en from './img/pavlosmelas_1_en.png';
import polichni from './img/polichni.png';
import polichni_en from './img/polichni_en.png';
import stavroupoli from './img/stavroupoli.png';
import stavroupoli_en from './img/stavroupoli_en.png';
import efkarpia from './img/efkarpia.png';
import efkarpia_en from './img/efkarpia_en.png';

import Civitavecchia from './img/civitavecchia.png';
import Civitavecchia_en from './img/civitavecchia_en.png';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',

            regions: false,
            data: null,

            theData: [],

            // mortality: [],

            selectedRegion: null,

            img: null,

            today_data: null,
            today_UTCI_title: '',
            today_UTCI_value: null,
            today_UTCI_unit: '',
            today_UTCI_fixed: null,
            today_temperature: null,
            today_tapp: null,
            today_humidity: null,
            today_francesca: null,
            today_color: 'whitesmoke',

            tomorrow_data: null,
            tomorrow_UTCI_title: '',
            tomorrow_UTCI_value: null,
            tomorrow_UTCI_unit: '',
            tomorrow_UTCI_fixed: null,
            tomorrow_temperature: null,
            tomorrow_tapp: null,
            tomorrow_humidity: null,
            tomorrow_francesca: null,
            tomorrow_color: 'whitesmoke',

            afterTomorrow_data: null,
            afterTomorrow_UTCI_title: '',
            afterTomorrow_UTCI_value: null,
            afterTomorrow_UTCI_unit: '',
            afterTomorrow_UTCI_fixed: null,
            afterTomorrow_temperature: null,
            afterTomorrow_tapp: null,
            afterTomorrow_humidity: null,
            afterTomorrow_francesca: null,
            afterTomorrow_color: 'whitesmoke',

            afterAfterTomorrow_data: null,
            afterAfterTomorrow_UTCI_title: '',
            afterAfterTomorrow_UTCI_value: null,
            afterAfterTomorrow_UTCI_unit: '',
            afterAfterTomorrow_UTCI_fixed: null,
            afterAfterTomorrow_temperature: null,
            afterAfterTomorrow_tapp: null,
            afterAfterTomorrow_humidity: null,
            afterAfterTomorrow_francesca: null,
            afterAfterTomorrow_color: 'whitesmoke',
        };

        this.img = [
            {region: 'thess_a', img: thess_a},
            {region: 'thess_b', img: thess_b},
            {region: 'thess_c', img: thess_c},
            {region: 'thess_d', img: thess_d},
            {region: 'thess_e', img: thess_e},
            {region: 'thess_f', img: thess_f},

            {region: 'Distretto_1', img: Distretto_1},
            {region: 'Distretto_2', img: Distretto_2},
            {region: 'Distretto_3', img: Distretto_3},
            {region: 'Distretto_4', img: Distretto_4},
            {region: 'Distretto_5', img: Distretto_5},
            {region: 'Distretto_6', img: Distretto_6},
            {region: 'Distretto_7', img: Distretto_7},
            {region: 'Distretto_8', img: Distretto_8},
            {region: 'Distretto_9', img: Distretto_9},
            {region: 'Municipio_X', img: Municipio_X},
            {region: 'Municipio_XI', img: Municipio_XI},
            {region: 'Municipio_XII', img: Municipio_XII},
            {region: 'Distretto_13', img: Distretto_13},
            {region: 'Distretto_14', img: Distretto_14},
            {region: 'Distretto_15', img: Distretto_15},

            {region: 'heraklion_1', img: heraklion_1},
            {region: 'heraklion_2', img: heraklion_2},
            {region: 'heraklion_3', img: heraklion_3},

            {region: 'pavlosmelas_1', img: pavlosmelas_1},
            {region: 'polichni', img: polichni},
            {region: 'stavroupoli', img: stavroupoli},
            {region: 'efkarpia', img: efkarpia},
            
            {region: 'Civitavecchia', img: Civitavecchia},

        ];

        this.img_en = [
            {region: 'thess_a', img: thess_a_en},
            {region: 'thess_b', img: thess_b_en},
            {region: 'thess_c', img: thess_c_en},
            {region: 'thess_d', img: thess_d_en},
            {region: 'thess_e', img: thess_e_en},
            {region: 'thess_f', img: thess_f_en},

            {region: 'Distretto_1', img: Distretto_1},
            {region: 'Distretto_2', img: Distretto_2},
            {region: 'Distretto_3', img: Distretto_3},
            {region: 'Distretto_4', img: Distretto_4},
            {region: 'Distretto_5', img: Distretto_5},
            {region: 'Distretto_6', img: Distretto_6},
            {region: 'Distretto_7', img: Distretto_7},
            {region: 'Distretto_8', img: Distretto_8},
            {region: 'Distretto_9', img: Distretto_9},
            {region: 'Municipio_X', img: Municipio_X},
            {region: 'Municipio_XI', img: Municipio_XI},
            {region: 'Municipio_XII', img: Municipio_XII},
            {region: 'Distretto_13', img: Distretto_13},
            {region: 'Distretto_14', img: Distretto_14},
            {region: 'Distretto_15', img: Distretto_15},

            {region: 'heraklion_1', img: heraklion_1_en},
            {region: 'heraklion_2', img: heraklion_2_en},
            {region: 'heraklion_3', img: heraklion_3_en},

            {region: 'pavlosmelas_1', img: pavlosmelas_1_en},
            {region: 'polichni', img: polichni_en},
            {region: 'stavroupoli', img: stavroupoli_en},
            {region: 'efkarpia', img: efkarpia_en},
            
            {region: 'Civitavecchia', img: Civitavecchia_en},

        ];

        this.setData = this.setData.bind(this);
        this.setSourceData = this.setSourceData.bind(this);
    }

    componentDidMount() {
        let self = this;

        let a = new Api('getregiondata/city/' + this.props.city);
        a.Get().then((response) => {
            return response.json();
        }).then(function (json) {

            let regions = [];
            let test = null;
            json.forEach(function (item) {
                regions.indexOf(item.region) === -1 ? regions.push(item.region) : test = 1;
                item.date = item.date_time.split(" ")[0];
                item.time = item.date_time.split(" ")[1];
                item.utci_max = Number(item.utci_max);
                item.utci_min = Number(item.utci_min);
                item.utci_avg = Number(item.utci_avg);
                item.temp_max = Number(item.temp_max);
                item.temp_min = Number(item.temp_min);
                item.temp_avg = Number(item.temp_avg);
                item.tapp_max = Number(item.tapp_max);
                item.tapp_min = Number(item.tapp_min);
                item.tapp_avg = Number(item.tapp_avg);
                item.hum_max = Number(item.hum_max);
                item.hum_min = Number(item.hum_min);
                item.hum_avg = Number(item.hum_avg);
            });

            let result = _.groupBy(json, "region");

            let objs = [];
            _.forEach(result, function (value, key) {

                let r = _.chain(value).groupBy("date").value();

                let f = [];
                _.forEach(r, function (v0, k0) {
                    let utci_min = _.minBy(v0, 'utci_min');
                    let utci_max = _.maxBy(v0, 'utci_max');
                    let utci_avg = _.meanBy(v0, 'utci_avg');
                    let temp_max = _.maxBy(v0, 'temp_max');
                    let temp_min = _.minBy(v0, 'temp_min');
                    let temp_avg = _.meanBy(v0, 'temp_avg');
                    let tapp_max = _.maxBy(v0, 'tapp_max');
                    let tapp_min = _.minBy(v0, 'tapp_min');
                    let tapp_avg = _.meanBy(v0, 'tapp_avg');
                    let hum_max = _.maxBy(v0, 'hum_max');
                    let hum_min = _.minBy(v0, 'hum_min');
                    let hum_avg = _.meanBy(v0, 'hum_avg');

                    f.push({
                        date: k0,
                        utci_min: utci_min.utci_min,
                        utci_max: utci_max.utci_max,
                        utci_avg: utci_avg,
                        temp_max: temp_max.temp_max,
                        temp_min: temp_min.temp_min,
                        temp_avg: temp_avg,
                        tapp_max: tapp_max.tapp_max,
                        tapp_min: tapp_min.tapp_min,
                        tapp_avg: tapp_avg,
                        hum_max: hum_max.hum_max,
                        hum_min: hum_min.hum_min,
                        hum_avg: hum_avg,
                        all_data: v0
                    })
                });

                objs.push({
                    region: key,
                    data: f
                })

            });

            // self.setState({
            //     regions: regions,
            //     selectedRegion: regions[0],
            //     data: objs
            // }, function () {
            //     self.setData();
            // });

            let b = new Api('getmortalitydata');
            b.Get().then((response2) => {
                return response2.json();
            }).then(function (json2) {
                self.setState({
                    regions: regions,
                    selectedRegion: regions[0],
                    data: objs,
                    // mortality: self.props.role === roles.AUTHORIZED ?  json2 : []
                    mortality: json2
                }, function () {
                    self.setData();
                });
            });

        });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.i18n.pending !== prevProps.i18n.pending && this.props.i18n.locale === prevProps.i18n.locale){
            this.setData()
        }
        
    }

    setData(e) {

        let region = this.state.selectedRegion;

        if (e) {       
            region = e.target.value;
            this.setState({
                selectedRegion: e.target.value
            },() => this.setSourceData(region));
        }else{
            this.setSourceData(region)
        }

        
    }

    setSourceData(region){

        let theData = null;
        this.state.data.forEach(function (d) {
            if (d.region === region) {
                theData = d.data;
            }
        });

        try {

            theData.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date)
            });
            theData.forEach(function (item) {

                item.all_data.sort(function (a, b) {

                    let dateTimeParts_a = a.date_time.split(/[- :]/);
                    dateTimeParts_a[1]--;

                    let dateTimeParts_b = b.date_time.split(/[- :]/);
                    dateTimeParts_b[1]--;

                    return new Date(...dateTimeParts_a) - new Date(...dateTimeParts_b)
                })
            })

            let region_img = null;
            if(this.props.i18n.locale === 'el'){
                this.img.forEach(function (item) {
                    if(item.region === region){
                        region_img = item.img
                    }
                });
            }else{
                this.img_en.forEach(function (item) {
                    if(item.region === region){
                        region_img = item.img
                    }
                });
            }            

            let today = moment().format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'days').format('YYYY-MM-DD');
            let afterAfterTomorrow = moment().add(3, 'days').format('YYYY-MM-DD');

            let todayData = null;
            let tomorrowData = null;
            let afterTomorrowData = null;
            let afterAfterTomorrowData = null;

            theData.forEach(function (item) {
                if(item.date === today){
                    todayData = item
                }
                if(item.date === tomorrow){
                    tomorrowData = item
                }
                if(item.date === afterTomorrow){
                    afterTomorrowData = item
                }
                if(item.date === afterAfterTomorrow){
                    afterAfterTomorrowData = item
                }
            });

            let mortality1 = null;
            let mortality2 = null;
            let mortality3 = null;
            let mortality4 = null;

            if(this.props.city === 'rome'){
                this.state.mortality.forEach(function (item) {
                    if(item.station === 'ROME' && item.city.split('_')[1] + '_' + item.city.split('_')[2] === region){

                        if(today === item.date){
                            mortality1 = item.level_d1;
                            mortality2 = item.level_d2;
                            mortality3 = item.level_d3;
                            mortality4 = item.level_d4;
                        }

                        if(moment().add(-1, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d2;
                            mortality2 = item.level_d3;
                            mortality3 = item.level_d4;
                        }

                        if(moment().add(-2, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d3;
                            mortality2 = item.level_d4;
                        }

                        if(moment().add(-3, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d4;
                        }
                    }
                })
            }

            if(this.props.city === 'civitavecchia'){
                this.state.mortality.forEach(function (item) {
                    if(item.station === 'CIVITAVECCHIA'){

                        if(today === item.date){
                            mortality1 = item.level_d1;
                            mortality2 = item.level_d2;
                            mortality3 = item.level_d3;
                            mortality4 = item.level_d4;
                        }

                        if(moment().add(-1, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d2;
                            mortality2 = item.level_d3;
                            mortality3 = item.level_d4;
                        }

                        if(moment().add(-2, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d3;
                            mortality2 = item.level_d4;
                        }

                        if(moment().add(-3, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d4;
                        }
                    }
                })
            }

            if(this.props.city === 'thessaloniki'){
                this.state.mortality.forEach(function (item) {
                    if(item.station === 'THESS' && item.city.split('_')[1] + '_' + item.city.split('_')[2] === region){

                        if(today === item.date){
                            mortality1 = item.level_d1;
                            mortality2 = item.level_d2;
                            mortality3 = item.level_d3;
                            mortality4 = item.level_d4;
                        }

                        if(moment().add(-1, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d2;
                            mortality2 = item.level_d3;
                            mortality3 = item.level_d4;
                        }

                        if(moment().add(-2, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d3;
                            mortality2 = item.level_d4;
                        }

                        if(moment().add(-3, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d4;
                        }
                    }
                })
            }

            if(this.props.city === 'heraklion'){
                this.state.mortality.forEach(function (item) {
                    if(item.station === 'HERAKL' && item.city.split('_')[1] + '_' + item.city.split('_')[2] === region){

                        if(today === item.date){
                            mortality1 = item.level_d1;
                            mortality2 = item.level_d2;
                            mortality3 = item.level_d3;
                            mortality4 = item.level_d4;
                        }

                        if(moment().add(-1, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d2;
                            mortality2 = item.level_d3;
                            mortality3 = item.level_d4;
                        }

                        if(moment().add(-2, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d3;
                            mortality2 = item.level_d4;
                        }

                        if(moment().add(-3, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d4;
                        }
                    }
                })
            }

            if(this.props.city === 'pavlosmelas'){
                this.state.mortality.forEach(function (item) {
                    if(item.station === 'PAVLOSMELAS' && item.city.split('_')[1] === region){

                        if(today === item.date){
                            mortality1 = item.level_d1;
                            mortality2 = item.level_d2;
                            mortality3 = item.level_d3;
                            mortality4 = item.level_d4;
                        }

                        if(moment().add(-1, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d2;
                            mortality2 = item.level_d3;
                            mortality3 = item.level_d4;
                        }

                        if(moment().add(-2, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d3;
                            mortality2 = item.level_d4;
                        }

                        if(moment().add(-3, 'days').format('YYYY-MM-DD') === item.date){
                            mortality1 = item.level_d4;
                        }
                    }
                })
            }

            // todayData = theData[0];
            // tomorrowData = theData[1];
            // afterTomorrowData = theData[2];

            this.setState({
                theData: theData,
                img: region_img,
            });

            if(todayData !== null){
                this.setState({
                    today_data: todayData,
                    today_UTCI_title: '',
                    today_UTCI_value: todayData.utci_avg,
                    today_UTCI_unit: '',
                    today_UTCI_fixed: 0,
                    today_temperature: todayData.temp_avg.toFixed(1),
                    today_tapp: todayData.tapp_max.toFixed(1),
                    today_humidity: todayData.hum_avg.toFixed(0),
                    today_francesca: mortality1,
                });
            }

            if(tomorrowData !== null){
                this.setState({
                    tomorrow_data: tomorrowData,
                    tomorrow_UTCI_title: '',
                    tomorrow_UTCI_value: tomorrowData.utci_avg,
                    tomorrow_UTCI_unit: '',
                    tomorrow_UTCI_fixed: 0,
                    tomorrow_temperature: tomorrowData.temp_avg.toFixed(1),
                    tomorrow_tapp: tomorrowData.tapp_max.toFixed(1),
                    tomorrow_humidity: tomorrowData.hum_avg.toFixed(0),
                    tomorrow_francesca: mortality2,
                });
            }

            if(afterTomorrowData !== null){
                this.setState({
                    afterTomorrow_data: afterTomorrowData,
                    afterTomorrow_UTCI_title: '',
                    afterTomorrow_UTCI_value: afterTomorrowData.utci_avg,
                    afterTomorrow_UTCI_unit: '',
                    afterTomorrow_UTCI_fixed: 0,
                    afterTomorrow_temperature: afterTomorrowData.temp_avg.toFixed(1),
                    afterTomorrow_tapp: afterTomorrowData.tapp_max.toFixed(1),
                    afterTomorrow_humidity: afterTomorrowData.hum_avg.toFixed(0),
                    afterTomorrow_francesca: mortality3,
                });
            }

            if(afterAfterTomorrowData !== null){
                this.setState({
                    afterAfterTomorrow_data: afterAfterTomorrowData,
                    afterAfterTomorrow_UTCI_title: '',
                    afterAfterTomorrow_UTCI_value: afterAfterTomorrowData.utci_avg,
                    afterAfterTomorrow_UTCI_unit: '',
                    afterAfterTomorrow_UTCI_fixed: 0,
                    afterAfterTomorrow_temperature: afterAfterTomorrowData.temp_avg.toFixed(1),
                    afterAfterTomorrow_tapp: afterAfterTomorrowData.tapp_max.toFixed(1),
                    afterAfterTomorrow_humidity: afterAfterTomorrowData.hum_avg.toFixed(0),
                    afterAfterTomorrow_francesca: mortality4,
                });
            }

        } catch (e) {
            // console.log(e);
            this.setState({

                img: null,

                today_data: null,
                today_UTCI_title: '',
                today_UTCI_value: null,
                today_UTCI_unit: '',
                today_UTCI_fixed: null,
                today_temperature: null,
                today_tapp: null,
                today_humidity: null,
                today_francesca: null,

                tomorrow_data: null,
                tomorrow_UTCI_title: '',
                tomorrow_UTCI_value: null,
                tomorrow_UTCI_unit: '',
                tomorrow_UTCI_fixed: null,
                tomorrow_temperature: null,
                tomorrow_tapp: null,
                tomorrow_humidity: null,
                tomorrow_francesca: null,

                afterTomorrow_data: null,
                afterTomorrow_UTCI_title: '',
                afterTomorrow_UTCI_value: null,
                afterTomorrow_UTCI_unit: '',
                afterTomorrow_UTCI_fixed: null,
                afterTomorrow_temperature: null,
                afterTomorrow_tapp: null,
                afterTomorrow_humidity: null,
                afterTomorrow_francesca: null,

                afterAfterTomorrow_data: null,
                afterAfterTomorrow_UTCI_title: '',
                afterAfterTomorrow_UTCI_value: null,
                afterAfterTomorrow_UTCI_unit: '',
                afterAfterTomorrow_UTCI_fixed: null,
                afterAfterTomorrow_temperature: null,
                afterAfterTomorrow_tapp: null,
                afterAfterTomorrow_humidity: null,
                afterAfterTomorrow_francesca: null
            });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        const {messages} = this.props.i18n || {messages: {}};

        return (

            <Container style={{margin: "-6em 1em 2em 1em"}}>
                <div>
                    <Nav tabs style={{position: 'relative'}}>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '1'})}
                                onClick={() => {
                                    this.toggle('1');
                                }}
                            >
                                {moment().format('DD-MM-YYYY')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '2'})}
                                onClick={() => {
                                    this.toggle('2');
                                }}
                            >
                                {moment().add(1, 'days').format('DD-MM-YYYY')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '3'})}
                                onClick={() => {
                                    this.toggle('3');
                                }}
                            >
                                {moment().add(2, 'days').format('DD-MM-YYYY')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '4'})}
                                onClick={() => {
                                    this.toggle('4');
                                }}
                            >
                                {moment().add(3, 'days').format('DD-MM-YYYY')}
                            </NavLink>
                        </NavItem>
                        <NavItem style={{position: 'absolute', right: '0em'}}>
                            <div style={{display: "inline-flex"}}>
                                <Label className="regionHide" for="exampleSelect"
                                       style={{
                                           marginRight: '1em',
                                           marginTop: '0.5em',
                                           width: '15em'
                                       }}><T>region</T>:</Label>
                                <Input type="select" name="select" id="exampleSelect" onChange={this.setData}
                                       defaultValue={this.state.selectedRegion}>
                                    {this.state.regions ? this.state.regions.map((e) => (
                                        <option key={e} value={e}>{messages[e]}</option>
                                    )) : ""}
                                </Input>
                            </div>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <DashboardTabPane tabId={"1"}
                                          role={this.props.role}
                                          conditions={this.props.conditions}
                                          chronicdisease={this.props.chronicdisease}
                                          city={this.props.city}
                                          img={this.state.img}
                                          region={this.state.selectedRegion}
                                          theData={this.state.today_data}
                                          UTCI_title={this.state.today_UTCI_title}
                                          UTCI_value={this.state.today_UTCI_value}
                                          UTCI_unit={this.state.today_UTCI_unit}
                                          UTCI_fixed={this.state.today_UTCI_fixed}
                                          temperature={this.state.today_temperature}
                                          tapp={this.state.today_tapp}
                                          humidity={this.state.today_humidity}
                                          francesca={this.state.today_francesca}
                                          color={this.state.today_color}
                                          messages={messages}/>
                        <DashboardTabPane tabId={"2"}
                                          role={this.props.role}
                                          conditions={this.props.conditions}
                                          chronicdisease={this.props.chronicdisease}
                                          city={this.props.city}
                                          img={this.state.img}
                                          region={this.state.selectedRegion}
                                          theData={this.state.tomorrow_data}
                                          UTCI_title={this.state.tomorrow_UTCI_title}
                                          UTCI_value={this.state.tomorrow_UTCI_value}
                                          UTCI_unit={this.state.tomorrow_UTCI_unit}
                                          UTCI_fixed={this.state.tomorrow_UTCI_fixed}
                                          temperature={this.state.tomorrow_temperature}
                                          tapp={this.state.tomorrow_tapp}
                                          humidity={this.state.tomorrow_humidity}
                                          francesca={this.state.tomorrow_francesca}
                                          color={this.state.tomorrow_color}
                                          messages={messages}/>
                        <DashboardTabPane tabId={"3"}
                                          role={this.props.role}
                                          conditions={this.props.conditions}
                                          chronicdisease={this.props.chronicdisease}
                                          city={this.props.city}
                                          img={this.state.img}
                                          region={this.state.selectedRegion}
                                          theData={this.state.afterTomorrow_data}
                                          UTCI_title={this.state.afterTomorrow_UTCI_title}
                                          UTCI_value={this.state.afterTomorrow_UTCI_value}
                                          UTCI_unit={this.state.afterTomorrow_UTCI_unit}
                                          UTCI_fixed={this.state.afterTomorrow_UTCI_fixed}
                                          temperature={this.state.afterTomorrow_temperature}
                                          tapp={this.state.afterTomorrow_tapp}
                                          humidity={this.state.afterTomorrow_humidity}
                                          francesca={this.state.afterTomorrow_francesca}
                                          color={this.state.afterAfterTomorrow_color}
                                          messages={messages}/>
                        <DashboardTabPane tabId={"4"}
                                          role={this.props.role}
                                          conditions={this.props.conditions}
                                          chronicdisease={this.props.chronicdisease}
                                          city={this.props.city}
                                          img={this.state.img}
                                          region={this.state.selectedRegion}
                                          theData={this.state.afterAfterTomorrow_data}
                                          UTCI_title={this.state.afterAfterTomorrow_UTCI_title}
                                          UTCI_value={this.state.afterAfterTomorrow_UTCI_value}
                                          UTCI_unit={this.state.afterAfterTomorrow_UTCI_unit}
                                          UTCI_fixed={this.state.afterAfterTomorrow_UTCI_fixed}
                                          temperature={this.state.afterAfterTomorrow_temperature}
                                          tapp={this.state.afterAfterTomorrow_tapp}
                                          humidity={this.state.afterAfterTomorrow_humidity}
                                          francesca={this.state.afterAfterTomorrow_francesca}
                                          color={this.state.afterAfterTomorrow_color}
                                          messages={messages}/>
                    </TabContent>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n,
    role: state.profile.user.role,
    conditions: state.profile.user.conditions,
    chronicdisease: state.profile.user.chronicdisease
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
