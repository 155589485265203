import React, { Component } from 'react';
import { Button } from 'reactstrap';
import 'apexcharts';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

class ChartLine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            region: props.region,
            categories: props.categories,
            yaxisTitle: props.yaxisTitle,
            xaxisTitle: props.xaxisTitle,
            series: props.series.map((item) => {
                return {
                    name: props.i18n.messages[item.name],
                    data: item.data
                }
            }),
            options: {
                chart: {
                    id: 'apexchart-forecast',
                    toolbar: {
                        autoSelected: 'zoom',
                        tools: {
                            download: true
                        }
                    }
                },
                stroke: {
                    width: 2,
                    curve: 'straight'
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    title: {
                        text: props.i18n[props.yaxisTitle]
                    }
                },
                xaxis: {
                    categories: props.categories,
                    title: {
                        text: props.i18n[props.xaxisTitle]
                    },
                    labels: {
                        formatter: function (value, timestamp, index) {
                            if (value === undefined || value === null) {
                                return ''
                            } else if (value.format("HH:mm") === '00:00') {
                                return value.format("DD/MM HH:mm")
                            } else {
                                return value.format("HH:mm")
                            }
                        }
                    }
                }
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.region !== this.state.region || this.props.i18n.messages !== prevProps.i18n.messages) {

            this.setState({
                region: this.props.region,
                categories: this.props.categories,
                yaxisTitle: this.props.yaxisTitle,
                xaxisTitle: this.props.xaxisTitle,
                series: this.props.series.map((item) => {
                    return {
                        name: this.props.i18n.messages[item.name],
                        data: item.data
                    }
                }),
                options: {
                    chart: {
                        id: 'apexchart-forecast',
                        toolbar: {
                            autoSelected: 'zoom',
                            tools: {
                                download: true
                            }
                        }
                    },
                    stroke: {
                        width: 2,
                        curve: 'straight'
                    },
                    tooltip: {
                        x: {
                            format: 'dd MMM yyyy'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        title: {
                            text: this.props.i18n[this.props.yaxisTitle]
                        }
                    },
                    xaxis: {
                        categories: this.props.categories,
                        title: {
                            text: this.props.i18n[this.props.xaxisTitle]
                        },
                        labels: {
                            formatter: function (value, timestamp, index) {
                                if (value === undefined || value === null) {
                                    return ''
                                } else if (value.format("HH:mm") === '00:00') {
                                    return value.format("DD/MM HH:mm")
                                } else {
                                    return value.format("HH:mm")
                                }
                            }
                        }
                    }
                }
            })
        }
    }


    render() {

        const { categories, series, options } = this.state;

        return (
            <div style={{ height: '90%' }}>
                <Button color="secondary" size="sm"
                    style={{
                        position: 'absolute',
                        right: '3.5em',
                        fontSize: 'xx-small',
                        zIndex: '800'
                    }} onClick={(e) => {
                        series.forEach(function (data) {
                            let downloadArray = [];
                            data.data.forEach(function (value, index) {
                                downloadArray.push([categories[index].format('DD/MM/YYYY HH:mm'), value])
                            });
                            let csvContent = "data:text/csv;charset=utf-8," + downloadArray.map(e => e.join(",")).join("\n");
                            let encodedUri = encodeURI(csvContent);
                            let link = document.createElement("a");
                            link.setAttribute("href", encodedUri);
                            link.setAttribute("download", data.name + ".csv");
                            document.body.appendChild(link);
                            link.click();
                        })
                    }
                    }>Download
                    Data</Button>
                <Chart options={options} series={series} type={'line'} width={'100%'}
                    height={'100%'} style={{ position: 'relative' }} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n
});

ChartLine = connect(mapStateToProps)(ChartLine);

export default ChartLine;
