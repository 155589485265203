import React, { Component } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from "ol/layer/Tile";
import { transform } from 'ol/proj';
import BingMaps from "ol/source/BingMaps";
import TileWMS from "ol/source/TileWMS";

class MiniMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null
        };

        this.regionLayer = null;
        this.CQL_FILTER_region = null;
    }

    componentDidMount() {
        let t = this;

        let part1 = null;
        let center = null;
        let zoom = null;
        let CQL_FILTER_region = '';
        if (t.props.city === 'rome') {
            part1 = 'ROME';
            center = [12.4905242, 41.9023711];
            zoom = 10;
            CQL_FILTER_region = "Distretti='" + this.props.region + "'";
        } else if (t.props.city === 'thessaloniki') {
            part1 = 'THESS';
            center = [22.9485878, 40.6372534];
            zoom = 12;
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (t.props.city === 'heraklion') {
            part1 = 'HERAKL';
            center = [25.1378434, 35.3317384];
            zoom = 13;
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (t.props.city === 'pavlosmelas') {
            part1 = 'THESS';
            center = [22.9485878, 40.685];
            zoom = 13;
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (t.props.city === 'civitavecchia') {
            part1 = 'ROME';
            center = [11.80, 42.10];
            zoom = 11;
            CQL_FILTER_region = "region='" + this.props.region + "'";
        }

        let part2 = null;
        if (t.props.id.split('_')[0] === 'RelHum') {
            part2 = 'RelHum_max_';
        } else if (t.props.id.split('_')[0] === 'Temperature') {
            part2 = 'Temperature_max_';
        } else if (t.props.id.split('_')[0] === 'TAPP') {
            part2 = 'TAPP_max_';
        } else if (t.props.id.split('_')[0] === 'UTCI') {
            part2 = 'UTCI_max_';
        }

        let part3 = null;
        if (t.props.id.split('_')[1] === '1') {
            part3 = 'today';
        } else if (t.props.id.split('_')[1] === '2') {
            part3 = 'tomorrow';
        } else if (t.props.id.split('_')[1] === '3') {
            part3 = 'afterTomorrow';
        } else if (t.props.id.split('_')[1] === '4') {
            part3 = 'afterAfterTomorrow';
        }

        const layerName = part1 + part2 + part3;
        const layerRegion = this.props.city + '_region';

        let layers = [];
        layers.push(new TileLayer({
            id: 'Aerial',
            title: 'Aerial',
            baseLayer: true,
            attributeTable: false,
            swipe: false,
            visible: true,
            source: new BingMaps({
                key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                imagerySet: 'AerialWithLabels'
            })
        }));

        layers.push(new TileLayer({
            id: layerName,
            title: layerName,
            label: layerName,
            baseLayer: false,
            visible: true,
            source: new TileWMS({
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                params: {
                    LAYERS: layerName
                }
            }),
            opacity: 0.6
        }));

        let regionLayer = new TileLayer({
            id: layerRegion,
            title: layerRegion,
            label: layerRegion,
            baseLayer: false,
            visible: true,
            source: new TileWMS({
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                params: {
                    LAYERS: layerRegion,
                    CQL_FILTER: CQL_FILTER_region
                }
            }),
            opacity: 1
        });

        layers.push(regionLayer);


        let map = new Map({
            layers: layers,
            target: t.props.id,
            view: new View({
                projection: "EPSG:3857",
                center: transform(center, 'EPSG:4326', "EPSG:3857"),
                zoom: zoom
            }),
            controls: []
        });

        this.regionLayer = regionLayer;
        this.CQL_FILTER_region = CQL_FILTER_region;

        this.setState({
            map: map
        })

    }

    componentDidUpdate(prevProps, prevState) {

        let CQL_FILTER_region = '';

        if (this.props.city === 'rome') {
            CQL_FILTER_region = "Distretti='" + this.props.region + "'";
        } else if (this.props.city === 'thessaloniki') {
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (this.props.city === 'heraklion') {
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (this.props.city === 'pavlosmelas') {
            CQL_FILTER_region = "region='" + this.props.region + "'";
        } else if (this.props.city === 'civitavecchia') {
            CQL_FILTER_region = "region='" + this.props.region + "'";
        }

        this.state.map.removeLayer(this.regionLayer);

        const id = this.regionLayer.get('id');
        const title = this.regionLayer.get('title');
        const label = this.regionLayer.get('label');

        this.regionLayer = new TileLayer({
            id: id,
            title: title,
            label: label,
            baseLayer: false,
            visible: true,
            source: new TileWMS({
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                params: {
                    LAYERS: id,
                    CQL_FILTER: CQL_FILTER_region
                }
            }),
            opacity: 1
        });

        this.state.map.addLayer(this.regionLayer);

        this.state.map.updateSize();

    }

    render() {

        return (
            <div id={this.props.id} style={{ height: '500px' }} />
        );
    }
}

export default MiniMap;