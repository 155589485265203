import React, {Component} from 'react';
import T from 'modules/i18n';
// import moment from 'moment';
import './UTCI.css';
import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

class UTCI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: null,
            label: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            // let measure_date = moment(this.props.timestamp);
            // let now_date = moment();
            // let diff = now_date.diff(measure_date, 'h');

            if (this.props.value === null || this.props.value < -40) {
                this.setState({
                    index: 'utci-0'
                })
            } else if (this.props.value >= -40 && this.props.value < -27) {
                this.setState({
                    index: 'utci-1',
                    label: 'UTCI1'
                })
            } else if (this.props.value >= -27 && this.props.value < -13) {
                this.setState({
                    index: 'utci-2',
                    label: 'UTCI2'
                })
            } else if (this.props.value >= -13 && this.props.value < 0) {
                this.setState({
                    index: 'utci-3',
                    label: 'UTCI3'
                })
            } else if (this.props.value >= 0 && this.props.value < 9) {
                this.setState({
                    index: 'utci-4',
                    label: 'UTCI4'
                })
            } else if (this.props.value >= 9 && this.props.value < 26) {
                this.setState({
                    index: 'utci-5',
                    label: 'UTCI5'
                })
            } else if (this.props.value >= 26 && this.props.value < 32) {
                this.setState({
                    index: 'utci-6',
                    label: 'UTCI6'
                })
            } else if (this.props.value >= 32 && this.props.value < 38) {
                this.setState({
                    index: 'utci-7',
                    label: 'UTCI7'
                })
            } else if (this.props.value >= 38 && this.props.value < 46) {
                this.setState({
                    index: 'utci-8',
                    label: 'UTCI8'
                })
            } else {
                this.setState({
                    index: 'utci-0'
                })
            }
        }
    }

    render() {
        const {value, unit, fixed} = this.props;

        return (
            <Card style={{position: 'relative'}}>
                <CardBody>
                    <CardTitle>
                        <Tooltip
                            placement="top"
                            overlay={<T>UTCISortDescription</T>}
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <h4><T>UTCI</T> - <T>dailyAverage</T></h4>
                        </Tooltip>
                    </CardTitle>
                    <CardText className={'dashboardTabPane-horizontal-center'} style={{width: '100%', textAlign: 'center'}}>
                        {value ?
                            <div>{value.toFixed(fixed)}</div>
                            : ''}
                    </CardText>
                </CardBody>
            </Card>
        );
    }
}

// UTCI.propTypes = {
//     value: PropTypes.number.isRequired,
//     unit: PropTypes.string.isRequired,
//     fixed: PropTypes.number.isRequired
// };

export default UTCI;
