import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import MyMap from '../../components/map';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'
import ReactGA from "react-ga";


class Civitavecchia extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        ReactGA.pageview('/civitavecchia');
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/civitavecchia');
    // }

    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'AerialWithLabels'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMECDD',
                title: 'CDD',
                styles: [],
                legend: ['img/legends/cdd_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEDewTemperature',
                title: 'DewTemperature',
                styles: ['LIFEASTI:temp_annual_rome','LIFEASTI:temp_spring_rome','LIFEASTI:temp_summer_rome','LIFEASTI:temp_autumn_rome','LIFEASTI:temp_winter_rome'],
                legend: ['img/legends/temp_rome_annual.png','img/legends/temp_rome_spring.png','img/legends/temp_rome_summer.png','img/legends/temp_rome_autumn.png','img/legends/temp_rome_winter.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEDI',
                title: 'DI',
                styles: ['LIFEASTI:temp_annual_rome','LIFEASTI:temp_spring_rome','LIFEASTI:temp_summer_rome','LIFEASTI:temp_autumn_rome','LIFEASTI:temp_winter_rome'],
                legend: ['img/legends/di_rome_annual.png','img/legends/di_rome_spring.png','img/legends/di_rome_summer.png','img/legends/di_rome_autumn.png','img/legends/di_rome_winter.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEHDD',
                title: 'HDD',
                styles: [],
                legend: ['img/legends/hdd_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMELWUP',
                title: 'LWUP',
                styles: [],
                legend: ['img/legends/lwup_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMESWDOWN',
                title: 'SWDOWN',
                styles: [],
                legend: ['img/legends/swdown_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMETAPP',
                title: 'TAPP',
                styles: ['LIFEASTI:temp_annual_rome','LIFEASTI:temp_spring_rome','LIFEASTI:temp_summer_rome','LIFEASTI:temp_autumn_rome','LIFEASTI:temp_winter_rome'],
                legend: ['img/legends/tapp_rome_annual.png','img/legends/tapp_rome_spring.png','img/legends/tapp_rome_summer.png','img/legends/tapp_rome_autumn.png','img/legends/tapp_rome_winter.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEUHII',
                title: 'UHII',
                styles: [],
                legend: ['img/legends/uhi_int.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMECLDFRA',
                title: 'CLDFRA',
                styles: [],
                legend: ['img/legends/cld_fra_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMERelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMETemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_rome','LIFEASTI:temp_spring_rome','LIFEASTI:temp_summer_rome','LIFEASTI:temp_autumn_rome','LIFEASTI:temp_winter_rome'],
                legend: ['img/legends/temp_rome_annual.png','img/legends/temp_rome_spring.png','img/legends/temp_rome_summer.png','img/legends/temp_rome_autumn.png','img/legends/temp_rome_winter.png'],
                visible: true,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEWind',
                title: 'Wind',
                styles: ['LIFEASTI:windspeed2', 'LIFEASTI:wind_barbs', 'LIFEASTI:wind_arrows'],
                legend: ['img/legends/wind_spd_all.png', 'img/legends/wind_barbs.png', 'img/legends/wind_arrows.png'],
                visible: false,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'civitavecchia_border',
                title: 'border',
                styles: [],
                legend: [],
                visible: true,
                extent: [[10.2004990, 39.9410138], [16.8582138, 44.0063474]],
                opacity: 1,
                attributeTable: true
            }
        ];

        return (

            <MyMap
                projection={"EPSG:3857"}
                center={[11.80, 42.10]}
                zoom={12}
                minZoom={8}
                maxZoom={18}
                extent={[[10.2004990, 39.9410138], [16.8582138, 44.0063474]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

Civitavecchia = connect(mapStateToProps)(Civitavecchia);

export default Civitavecchia;
