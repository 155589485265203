import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Container,
    Row,
    Col,
    CardTitle, CardText,
    Button, CardHeader, CardFooter,
    Modal, ModalHeader, ModalBody,
    UncontrolledCollapse,
    Card,
    CardBody, FormGroup, Label, Input,
    Form
} from 'reactstrap';
import T from 'modules/i18n';

import Map from 'ol/Map';
import {defaults as defaultControls} from 'ol/control';
import View from 'ol/View';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {fromLonLat} from 'ol/proj';
import Draw, {createBox} from 'ol/interaction/Draw';
import {transform} from 'ol/proj';
import 'ol/ol.css';
import {Api} from "core/api";
import {parseString} from 'xml2js';
import ReactGA from "react-ga";

class Μetadata extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Dew temperature",
            abstract: "",
            resourceidentifier: "",
            keyword: "",
            topiccategory: "",
            from: "",
            to: "",
            calendartype: "",
            type: "dataset",
            bbox: '-180,-90,180,90',
            where: "everywhere",

            results: false,
            metadata_results: false,

            modal: false,
            modalTitle: null,
            modalBody: null
        };

        this.map = null;
        this.draw = null;
        this.source = null;
        this.vector = null;

        this.handleChange = this.handleChange.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.getMetadataMore = this.getMetadataMore.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        ReactGA.pageview('/metadata');

        let t = this;

        t.source = new VectorSource({wrapX: false});
        t.vector = new VectorLayer({
            source: t.source
        });

        this.map = new Map({
            target: 'metadatamap',
            controls: defaultControls({
                attribution: false,
                zoom: false,
            }),
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                t.vector
            ],
            view: new View({
                center: fromLonLat([19.73, 39.36]),
                zoom: 4
            })
        });

        function addInteraction() {
            t.draw = new Draw({
                source: t.source,
                type: 'Circle',
                geometryFunction: createBox()
            });
            t.map.addInteraction(t.draw);
        }

        addInteraction();

        t.draw.on('drawstart', function () {
            t.source.clear();
        });
        t.draw.on('drawend', function (e) {
            let bbox = e.feature.getGeometry().extent_;
            t.setState({
                bbox: transform([bbox[0], bbox[1]], 'EPSG:3857', 'EPSG:4326').concat(transform([bbox[2], bbox[3]], 'EPSG:3857', 'EPSG:4326')).toString()
            })
        });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/metadata');
    // }

    handleChange(event) {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        }, function () {
            if (this.state.where === null || this.state.where === 'everywhere') {
                this.setState({
                    bbox: '-180,-90,180,90'
                })
            } else {
                let bbox = this.vector.getSource().getExtent();
                this.setState({
                    bbox: transform([bbox[0], bbox[1]], 'EPSG:3857', 'EPSG:4326').concat(transform([bbox[2], bbox[3]], 'EPSG:3857', 'EPSG:4326')).toString()
                })
            }
        });
    }

    sendRequest(e) {
        e.preventDefault();

        let t = this;

        t.setState({
            results: false,
            metadata_results: false,
            modalTitle: null,
            modalBody: null
        });

        let request = 'GetRecords' +
            '&title=' + this.state.title +
            '&abstract=' + this.state.abstract +
            '&resourceidentifier=' + this.state.resourceidentifier +
            '&keyword=' + this.state.keyword +
            '&topiccategory=' + this.state.topiccategory +
            '&bbox=' + this.state.bbox +
            '&from=' + this.state.from +
            '&to=' + this.state.to +
            '&calendartype=' + this.state.calendartype +
            '&type=' + this.state.type +
            '&search_request_method=advance'
        ;
        let a = new Api('metadata/' + request);

        a.Get().then(function (response) {
            return response.json();
        }).then(function (text) {
            parseString(text, function (err, result) {
                try {
                    let data = result['csw:GetRecordsResponse']['csw:SearchResults'][0]['gmd:MD_Metadata'];
                    if (data) {
                        t.setState({
                            results: true,
                            metadata_results: data
                        })
                    }
                } catch (e) {

                }

            });
        });
    }

    getMetadataMore(e) {
        let t = this;

        t.setState({
            modalTitle: null,
            modalBody: null
        });

        const id = e.target.id;

        let request = 'GetRecordById' +
            '&id=' + id;
        let a = new Api('metadata/' + request);

        a.Get().then(function (response) {
            return response.json();
        }).then(function (text) {
            parseString(text, function (err, result) {

                let item = result['csw:GetRecordByIdResponse']['gmd:MD_Metadata'][0];

                // console.log(item);

                let codeList = '';
                try {
                    codeList = item['gmd:hierarchyLevel'][0]['gmd:MD_ScopeCode'][0]['$']['codeListValue'];
                } catch (e) {
                }

                let title = '';
                try {
                    title = item['gmd:identificationInfo'][0]['gmd:MD_DataIdentification'][0]['gmd:citation'][0]['gmd:CI_Citation'][0]['gmd:title'][0]['gco:CharacterString'];
                } catch (e) {
                }


                let abstract = '';
                try {
                    abstract = item['gmd:identificationInfo'][0]['gmd:MD_DataIdentification'][0]['gmd:abstract'][0]['gco:CharacterString'][0];
                } catch (e) {
                }

                let fileIdentifier = '';
                try {
                    fileIdentifier = item['gmd:fileIdentifier'][0]['gco:CharacterString'][0];
                } catch (e) {
                }

                let language = '';
                try {
                    language = item['gmd:language'][0]['gmd:LanguageCode'][0]['$']['codeListValue'];
                } catch (e) {
                }

                let characterSet = '';
                try {
                    characterSet = item['gmd:characterSet'][0]['gmd:MD_CharacterSetCode'][0]['$']['codeListValue'];
                } catch (e) {
                }

                let organisationName = '';
                try {
                    organisationName = item['gmd:contact'][0]['gmd:CI_ResponsibleParty'][0]['gmd:organisationName'][0]['gco:CharacterString'][0];
                } catch (e) {
                }

                let contactInfo = '';
                try {
                    contactInfo = item['gmd:contact'][0]['gmd:CI_ResponsibleParty'][0]['gmd:contactInfo'][0]['gmd:CI_Contact'][0]['gmd:address'][0]['gmd:CI_Address'][0]['gmd:electronicMailAddress'][0]['gco:CharacterString'][0];
                } catch (e) {
                }

                let role = '';
                try {
                    role = item['gmd:contact'][0]['gmd:CI_ResponsibleParty'][0]['gmd:role'][0]['gmd:CI_RoleCode'][0]['$']['codeListValue'];
                } catch (e) {
                }

                let identificationInfo = '';
                try {
                    identificationInfo = item['gmd:identificationInfo'][0]['gmd:MD_DataIdentification'][0];
                } catch (e) {
                }

                let distributionInfo = '';
                try {
                    distributionInfo = item['gmd:distributionInfo'][0]['gmd:MD_Distribution'][0]['gmd:transferOptions'][0]['gmd:MD_DigitalTransferOptions'][0]['gmd:onLine']
                } catch (e) {
                }

                let termsOfUse = '';
                try {
                    termsOfUse = identificationInfo['gmd:resourceConstraints'][0]['gmd:MD_Constraints'][0]['gmd:useLimitation'][0]['gco:CharacterString'][0]
                } catch (e) {
                }

                let termsOfAccess = '';
                try {
                    termsOfAccess = identificationInfo['gmd:resourceConstraints'][1]['gmd:MD_LegalConstraints'][0]['gmd:otherConstraints'][0]['gco:CharacterString'][0]
                } catch (e) {
                }

                // console.log(identificationInfo);
                // console.log(distributionInfo);

                let body = <div>
                    <Button color="primary" id="toggler_general" style={{width: '100%'}}>
                        <T>general</T>
                    </Button>
                    <UncontrolledCollapse toggler="#toggler_general">
                        <Card>
                            <CardBody style={{overflowY: 'scroll', maxHeight: '200px'}}>
                                <ul>
                                    <li><b><T>fileIdentifier</T>:</b> {fileIdentifier}</li>
                                    <li><b><T>language</T>:</b> {language}</li>
                                    <li><b><T>characterSet</T>:</b> {characterSet}</li>
                                </ul>
                            </CardBody>
                        </Card>
                    </UncontrolledCollapse>
                    <Button color="primary" id="toggler_metadataAbout" style={{width: '100%'}}>
                        <T>metadataAbout</T>
                    </Button>
                    <UncontrolledCollapse toggler="#toggler_metadataAbout">
                        <Card>
                            <CardBody style={{overflowY: 'scroll', maxHeight: '200px'}}>
                                <ul>
                                    <li><b><T>organisationName</T>:</b> {organisationName}</li>
                                    <li><b><T>contactInfo</T>:</b> {contactInfo}</li>
                                    <li><b><T>metadataRole</T>:</b> {role}</li>
                                </ul>
                            </CardBody>
                        </Card>
                    </UncontrolledCollapse>
                    <Button color="primary" id="toggler_identification" style={{width: '100%'}}>
                        <T>Identification</T>
                    </Button>
                    <UncontrolledCollapse toggler="#toggler_identification">
                        <Card>
                            <CardBody style={{overflowY: 'scroll', maxHeight: '200px'}}>
                                <b><T>metadataΤitle</T></b>
                                <ul>
                                    <li>{title}</li>
                                </ul>
                                <b><T>metadataΑbstract</T></b>
                                <ul>
                                    <li>{abstract}</li>
                                </ul>
                                <b><T>termsOfUse</T></b>
                                <ul>
                                    <li>{termsOfUse}</li>
                                </ul>
                                <b><T>termsOfAccess</T></b>
                                <ul>
                                    <li>{termsOfAccess}</li>
                                </ul>
                                <b><T>metadataLink</T></b>
                                <ul>
                                    {
                                        distributionInfo.map((item) => {
                                            let i = item['gmd:CI_OnlineResource'][0]['gmd:linkage'][0]['gmd:URL'][0];
                                            return <li><a href={i} target={'new'}>{i}</a></li>
                                        })
                                    }
                                </ul>

                            </CardBody>
                            <CardFooter>
                                <Button onClick={(e) => {
                                    let blob = new Blob([text], {type: 'text/xml'});
                                    let url = URL.createObjectURL(blob);
                                    window.open(url);
                                    URL.revokeObjectURL(url);
                                }} style={{width: '100%'}}><T>XML</T></Button>
                            </CardFooter>
                        </Card>
                    </UncontrolledCollapse>
                </div>;

                t.setState({
                    modalTitle: codeList + ': ' + title,
                    modalBody: body
                }, function () {
                    t.toggle()
                })
            })
        });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const {messages} = this.props.i18n || {messages: {}};

        return (
            <div className="app-container flex-row align-items-center">
                <Container>

                    <Card>
                        <CardBody>
                            <CardTitle><h3>{messages.metadataForm}</h3></CardTitle>
                            <CardText>
                                <Row>
                                    <Col sm="6">
                                        <Card body>
                                            <CardTitle><h4>{messages.What}</h4></CardTitle>
                                            <CardText>
                                                <Form>
                                                    <FormGroup>
                                                        <Label for="title">{messages.Title}</Label>
                                                        <Input type="text" name="title" id="title"
                                                               onChange={this.handleChange} value={this.state.title}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="abstract">{messages.Abstract}</Label>
                                                        <Input type="text" name="abstract" id="abstract"
                                                               onChange={this.handleChange}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="resourceidentifier">{messages.Identifier}</Label>
                                                        <Input type="text" name="resourceidentifier"
                                                               id="resourceidentifier" onChange={this.handleChange}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="keyword">{messages.Keyword}</Label>
                                                        <Input type="text" name="keyword" id="keyword"
                                                               onChange={this.handleChange}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="topiccategory">{messages.Category}</Label>
                                                        <Input type="select" name="topiccategory" id="topiccategory"
                                                               onChange={this.handleChange}>
                                                            <option value={""}>{messages.all}</option>
                                                            <option
                                                                value={"imageryBaseMapsEarthCover"}>{messages.imageryBaseMapsEarthCover}</option>
                                                            <option value={"biota"}>{messages.biota}</option>
                                                            <option value={"farming"}>{messages.farming}</option>
                                                            <option value={"boundaries"}>{messages.boundaries}</option>
                                                            <option
                                                                value={"climatologyMeteorologyAtmosphere"}>{messages.climatologyMeteorologyAtmosphere}</option>
                                                            <option value={"economy"}>{messages.economy}</option>
                                                            <option value={"elevation"}>{messages.elevation}</option>
                                                            <option
                                                                value={"geoscientificInformation"}>{messages.geoscientificInformation}</option>
                                                            <option
                                                                value={"intelligenceMilitary"}>{messages.intelligenceMilitary}</option>
                                                            <option value={"health"}>{messages.health}</option>
                                                            <option
                                                                value={"inlandWaters"}>{messages.inlandWaters}</option>
                                                            <option value={"location"}>{messages.location}</option>
                                                            <option value={"oceans"}>{messages.oceans}</option>
                                                            <option
                                                                value={"planningCadastre"}>{messages.planningCadastre}</option>
                                                            <option value={"society"}>{messages.society}</option>
                                                            <option value={"structure"}>{messages.structure}</option>
                                                            <option
                                                                value={"transportation"}>{messages.transportation}</option>
                                                            <option
                                                                value={"utilitiesCommunication"}>{messages.utilitiesCommunication}</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Form>
                                            </CardText>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card body style={{minHeight: '35em'}}>
                                            <CardTitle><h4>{messages.Where}</h4></CardTitle>
                                            <CardText>
                                                <Form>
                                                    <FormGroup tag="fieldset">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" name="where" value="everywhere" checked
                                                                       onChange={this.handleChange}/>{' '}
                                                                {messages.Everywhere}
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" name="where" value="bbox"
                                                                       onChange={this.handleChange}/>{' '}
                                                                {messages.DesignΟnMap}
                                                            </Label>
                                                        </FormGroup>
                                                        <div id={"metadatamap"} style={{height: '24em'}}/>
                                                    </FormGroup>
                                                </Form>
                                            </CardText>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card body>
                                            <CardTitle><h4>{messages.When}</h4></CardTitle>
                                            <CardText>
                                                <Form>
                                                    <FormGroup>
                                                        <Label for="from">{messages.From}</Label>
                                                        <Input
                                                            type="date"
                                                            name="from"
                                                            id="from"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="to">{messages.To}</Label>
                                                        <Input
                                                            type="date"
                                                            name="to"
                                                            id="to"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="calendartype">{messages.Criteria}</Label>
                                                        <Input type="select" name="calendartype" id="calendartype"
                                                               onChange={this.handleChange}>
                                                            <option value={""}>{messages.alls}</option>
                                                            <option
                                                                value={"tempExtentDate"}>{messages.tempExtentDate}</option>
                                                            <option
                                                                value={"creationDate"}>{messages.creationDate}</option>
                                                            <option
                                                                value={"revisionDate"}>{messages.revisionDate}</option>
                                                            <option
                                                                value={"publicationDate"}>{messages.publicationDate}</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Form>
                                            </CardText>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card body>
                                            <CardTitle><h4>{messages.Type}</h4></CardTitle>
                                            <CardText>
                                                <FormGroup>
                                                    <Label for="type">{messages.ServiceType}</Label>
                                                    <Input type="select" name="type" id="type"
                                                           onChange={this.handleChange}>
                                                        <option value={"dataset"}>{messages.Dataset}</option>
                                                        <option value={"series"}>{messages.Series}</option>
                                                        <option value={"service"}>{messages.Service}</option>
                                                    </Input>
                                                </FormGroup>
                                            </CardText>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardText>
                            <Button style={{width: '100%'}} onClick={this.sendRequest}>{messages.search}</Button>
                        </CardBody>
                    </Card>

                    {this.state.results ?


                        <Card>
                            <CardBody>
                                <CardTitle><h3>{messages.metadataResults}</h3></CardTitle>
                                <CardText>
                                    <Row>
                                        {
                                            this.state.metadata_results.map((item) => {

                                                let fileIdentifier = '';
                                                try{
                                                    fileIdentifier = item['gmd:fileIdentifier'][0]['gco:CharacterString'][0];
                                                }catch (e) {

                                                }

                                                let codeList = '';
                                                try{
                                                    codeList = item['gmd:hierarchyLevel'][0]['gmd:MD_ScopeCode'][0]['$']['codeListValue'];
                                                }catch (e) {

                                                }

                                                let title = '';
                                                try{
                                                    title = item['gmd:identificationInfo'][0]['gmd:MD_DataIdentification'][0]['gmd:citation'][0]['gmd:CI_Citation'][0]['gmd:title'][0]['gco:CharacterString'][0];
                                                }catch (e) {

                                                }

                                                let abstract = '';
                                                try{
                                                    abstract = item['gmd:identificationInfo'][0]['gmd:MD_DataIdentification'][0]['gmd:abstract'][0]['gco:CharacterString'][0];
                                                }catch (e) {

                                                }

                                                let card = <Col sm="4">
                                                    <Card>
                                                        <CardHeader>{title}</CardHeader>
                                                        <CardBody>
                                                            <CardText>{messages.metadataΑbstract}: {abstract}</CardText>
                                                            <Button id={fileIdentifier}
                                                                    onClick={this.getMetadataMore}>{messages.metadataMore}</Button>
                                                        </CardBody>
                                                        <CardFooter>{messages.metadataΤype}: {codeList}</CardFooter>
                                                    </Card>
                                                </Col>;

                                                return card;
                                            })
                                        }
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>

                        :
                        ''
                    }

                    <Modal isOpen={this.state.modal} toggle={this.toggle}
                           style={{minWidth: '800px', maxWidth: '1000px'}}>
                        <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
                        <ModalBody>
                            {this.state.modalBody}
                        </ModalBody>
                    </Modal>

                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n
});

Μetadata = connect(mapStateToProps)(Μetadata);

export default Μetadata;
