import React, {Component} from 'react';
import {
    Container
} from 'reactstrap';

import Dashboard from '../../components/dashboard';
import ReactGA from "react-ga";


class HeraklionDashboard extends Component {

    componentDidMount() {
        ReactGA.pageview('/herakliondashboard');
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/herakliondashboard');
    // }

    render() {

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Dashboard
                        city={'heraklion'}
                    />
                </Container>
            </div>
        );
    }
}

export default HeraklionDashboard;

