import React, { Component } from 'react';
import { Nav } from 'reactstrap';

import { headerItems } from 'modules';
import HeaderDropdown from './headerDropdown';

class Header extends Component {

	render() {
		return (
			<Nav className="ml-auto navbar">
				<HeaderDropdown/>
				{ Object.values(headerItems).map((Item, index) => <Item key={`header_item_${index}`}/>) }
			</Nav>
		);
	}
}

export default Header;
