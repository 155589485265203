import React, {Component} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardText,
    Row,
    Col, CardBody
} from 'reactstrap';
import {defaults as defaultControls, ScaleLine} from 'ol/control';
import classnames from 'classnames';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {transform, fromLonLat} from 'ol/proj';
import LayerSwitcher from "../components/layercontrol/LayerSwitcher";
import "../components/layercontrol/LayerSwitcher.css";
import LegendInfoControl from '../components/legend/legend';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import TimeSlider from '../components/timeslider/timeslider'
import moment from 'moment';
import {PixelSpinner} from 'react-epic-spinners'
import {Api} from 'core/api';
import T from 'modules/i18n';
import {connect} from "react-redux";
import 'apexcharts';
// import Chart from 'react-apexcharts'
import ChartLine from '../components/chartlline/chartlline';
import {parseString} from 'xml2js';
import Feature from 'ol/Feature';
import {Point} from 'ol/geom';
import {Circle as CircleStyle, Fill, Text, Style} from 'ol/style';

class TheMapMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            min: null,
            max: null,
            time_series: null,
            showSpinner: false,
            modal: false,
            modalTitle: null,
            modalFooter: null,
            activeTab: '1',
            tab1: null,
            UTCI: false,
            Temperature: false,
            RelHum: false
        };
        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.addStations = this.addStations.bind(this);

        this.controller = new AbortController();
    }

    componentDidMount() {
        let t = this;
        t.controller = new AbortController();
        const {messages} = t.props.i18n || {messages: {}};
        let layers = [];
        layers.push(this.props.basemapLayers);
        this.props.mapLayers.map((e) => {
            if (e.type === 'TileLayer') {
                layers.push(
                    new TileLayer({
                        id: e.name,
                        title: e.title,
                        label: messages[e.title] || messages[e.name],
                        baseLayer: false,
                        swipe: true,
                        visible: e.visible,
                        styles: e.styles,
                        extent: transform(e.extent[0], 'EPSG:4326', t.props.projection).concat(transform(e.extent[1], 'EPSG:4326', t.props.projection)),
                        source: new TileWMS({
                            url: e.url,
                            params: {
                                LAYERS: e.name
                            }
                        }),
                        opacity: e.opacity,
                        attributeTable: e.attributeTable
                    })
                )
            } else if (e.type === 'ImageLayer') {
                layers.push(
                    new ImageLayer({
                        id: e.name,
                        title: e.title,
                        label: messages[e.title] || messages[e.name],
                        baseLayer: false,
                        swipe: true,
                        visible: e.visible,
                        styles: e.styles,
                        legend: e.legend,
                        extent: transform(e.extent[0], 'EPSG:4326', t.props.projection).concat(transform(e.extent[1], 'EPSG:4326', t.props.projection)),
                        source: new ImageWMS({
                            url: e.url,
                            params: {
                                LAYERS: e.name,
                                TILED: false
                            },
                            serverType: 'geoserver',
                            transition: 0
                        }),
                        opacity: e.opacity,
                        attributeTable: e.attributeTable
                    })
                )
            }
            return null;
        });

        let LegendControl = new LegendInfoControl();

        let map = new Map({
            layers: layers,
            controls: defaultControls({attribution: false}).extend([
                // new LayerPopup(),
                new LayerSwitcher({
                    style: true,
                    trash: true,
                    extent: true,
                    translates: t.props.i18n.messages
                }),
                new ScaleLine(),
                LegendControl,
                // new SwipeCustomControl(messages),
                // new AddSourcesCustomControl()
            ]),
            target: 'map',
            view: new View({
                projection: t.props.projection,
                center: transform(t.props.center, 'EPSG:4326', t.props.projection),
                zoom: t.props.zoom,
                minZoom: t.props.minZoom,
                maxZoom: t.props.maxZoom,
                extent: transform(t.props.extent[0], 'EPSG:4326', t.props.projection).concat(transform(t.props.extent[1], 'EPSG:4326', t.props.projection))
            })
        });
        LegendControl.set(map);

        map.on('singleclick', function (evt) {

            t.setState({
                showSpinner: true,
                tab1: null,
                activeTab: '1',
                UTCI: false,
                Temperature: false,
                RelHum: false
            });

            let viewResolution = map.getView().getResolution();

            let promises_info = [];
            let promises_info_layers = [];
            let promises_info_results = [];

            let timestamp = null;

            const layers = map.getLayers();
            layers.forEach(function (ly) {
                let url = null;
                if (!ly.get('baseLayer')) {
                    try {
                        url = ly.getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'application/json'});
                        timestamp = moment(ly.getSource().getParams().TIME).format('DD/MM/YYYY - HH:mm');
                    } catch (e) {

                    }
                }
                if (url) {
                    let request = url.replace('?', '&').replace(/%2F/g, '/');
                    let a = new Api('geoserver/' + request);
                    promises_info.push(
                        a.Get(t.controller.signal).then(function (response) {
                            return response.json();
                        })
                    );
                    promises_info_layers.push(ly)
                }
            });

            Promise.all(promises_info).then(function (response) {
                response.forEach(function (rs) {
                    try {
                        promises_info_results.push(JSON.parse(rs).features[0].properties.GRAY_INDEX)
                    } catch (e) {
                        promises_info_results.push('noData')
                    }
                });


                let cords = transform(evt.coordinate, t.props.projection, 'EPSG:4326');

                let title = messages.forecastFor + ': ' + timestamp;

                let tab1_0 = []
                let tab1 = promises_info_layers.map(function (value, index) {
                    let title = value.get('title');
                    let measure = promises_info_results[index] === 'noData' ? messages.noData : Number(promises_info_results[index]).toFixed(1);

                    if (title !== 'Wind' && title !== 'UTCI' && title !== 'Temperature' && title !== 'RelHum') {
                        return <tr>
                            <td style={{width: '40%'}}><b>{messages[title]}</b></td>
                            <td style={{width: '60%'}}>{measure}</td>
                        </tr>;
                    } else {
                        if (title !== 'Wind') {
                            tab1_0.push(
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><h6>{messages[title]}</h6></CardTitle>
                                            <CardText className={'dashboardTabPane-horizontal-center'}>
                                                {measure}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        }
                    }
                    return null;
                });

                console.log(tab1);

                let footer = messages.longitude + ': ' + cords[0].toFixed(6) + ' - ' + messages.latitude + ': ' + cords[1].toFixed(6);

                t.setState({
                    showSpinner: false,
                    modalTitle: title,
                    tab1_0: tab1_0,
                    tab1: tab1,
                    modalFooter: footer
                }, function () {
                    t.toggle();
                });


                //////////////////////////////////

                promises_info_layers.map(function (ly) {
                    if (ly.get('title') === 'UTCI' || ly.get('title') === 'Temperature' || ly.get('title') === 'RelHum') {
                        let url = ly.getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'application/json'});
                        if (url) {
                            let promises_diagram = [];

                            t.state.time_series.forEach(function (step) {
                                let request = url.replace('?', '&').replace(/%2F/g, '/') + '&time=' + step;
                                let a = new Api('geoserver/' + request);
                                promises_diagram.push(
                                    a.Get(t.controller.signal).then(function (response) {
                                        return response.json();
                                    })
                                )
                            });
                            Promise.all(promises_diagram).then(function (response) {
                                let r = [];
                                response.forEach(function (value) {
                                    let rs = null;
                                    try {
                                        rs = JSON.parse(value);
                                    } catch (e) {

                                    }
                                    if (rs !== null) {
                                        try {
                                            r.push(rs.features[0].properties);
                                        } catch (e) {
                                            r.push({GRAY_INDEX: null});
                                        }
                                    }
                                });

                                let categories = [];
                                let data = [];
                                let chart_data_tmp = [];
                                for (let j = 0; j < t.state.time_series.length; j+=3) {
                                    try {
                                        chart_data_tmp.push([
                                            moment(t.state.time_series[j]),
                                            r[j]['GRAY_INDEX'].toFixed(1)
                                        ])
                                    } catch (e) {

                                    }

                                }
                                let chart_data = chart_data_tmp.filter(function (value, index, arr) {
                                    return value[1] !== null;
                                });
                                for (let i = 0; i < chart_data.length; i++) {
                                    categories.push(chart_data[i][0]);
                                    data.push(chart_data[i][1]);
                                }

                                let series = [{
                                    name: messages[ly.get('title')],
                                    data: data
                                }];

                                let chrt = <ChartLine title={messages[ly.get('title')]} categories={categories}
                                                      series={series} yaxisTitle={messages[ly.get('title')]}
                                                      xaxisTitle={messages.date}/>

                                t.setState({
                                    [ly.get('title')]: chrt
                                })

                            })
                        }
                    }
                    return null;
                });


                /////////////////////////////////


            })
        });

        this.setState({
            map: map
        });

        this.SetTimeLine();

        this.props.romeStationsURL ? this.addStations() : console.log('no stations');
        window.setInterval(function () {
            t.addStations()
        }, 300000);

        map.on('pointermove', showInfo);
        let info = document.getElementById('info');

        function showInfo(event) {
            let features = map.getFeaturesAtPixel(event.pixel);
            if (!features) {
                info.innerText = '';
                info.style.opacity = 0;
                return;
            }
            let properties = features[0].getProperties();
            info.innerHTML = '<div>' + properties.titolo + '</div>' +
                '<div>Date-Time: ' + moment.unix(properties.timestamp).format('DD/MM/YYYY-HH:mm') + '</div>' +
                '<div>Temperatura: ' + properties.temp + ' °C</div>' +
                '<div>Max Temp: ' + properties.tempmax + ' °C</div>' +
                '<div>Min Temp: ' + properties.tempmin + ' °C</div>' +
                '<div>Wind: ' + properties.vento + ' Km/h</div>' +
                '<div>Wind Dir: ' + properties.ventodir + '</div>' +
                '<div>Humidity: ' + properties.hum + ' %</div>' +
                '<div>Rain Rate: ' + properties.rainrate + ' mm/h</div>' +
                '<div>Var.Press: ' + properties.press + ' hPa</div>';
            info.style.opacity = 1;
        }
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    SetTimeLine() {
        let now = moment().format('YYYY-MM-DD');
        let time_series = [];
        let min = moment(now.toString() + 'T03:00:00').valueOf();
        let max = min + (23 * 10800000);
        for (let i = min; i <= max; i = i + 10800000) {
            time_series.push(moment(i).toISOString());
        }

        this.setState({
            min: min,
            max: max,
            time_series: time_series
        })
    }

    ReadCapabilities() {
        let t = this;
        let parser = new WMSCapabilities();

        let request = 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms&version=1.3.0&request=GetCapabilities';
        let a = new Api('geoserver/' + request);

        a.Get(t.controller.signal).then(function (response) {
            return response.json();
        }).then(function (text) {
            let result = parser.read(text);
            const layers = result.Capability.Layer.Layer;
            let min = null;
            let max = null;
            layers.forEach(function (item) {
                try {
                    min = moment(item.Dimension[0].values.split('/')[0]).valueOf();
                    max = moment(item.Dimension[0].values.split('/')[1]).valueOf();
                } catch (e) {
                }
            });
            layers.forEach(function (item) {
                try {
                    if (moment(item.Dimension[0].values.split('/')[0]).valueOf() < min) {
                        min = moment(item.Dimension[0].values.split('/')[0]).valueOf();
                    }
                    if (moment(item.Dimension[0].values.split('/')[1]).valueOf() > max) {
                        max = moment(item.Dimension[0].values.split('/')[1]).valueOf();
                    }
                } catch (e) {
                }
            });

            let now = moment().format('YYYY-MM-DD');

            let time_series = [];
            min = moment(now.toString() + 'T03:00:00').valueOf();
            for (let i = min; i <= max; i = i + 10800000) {
                time_series.push(moment(i).toISOString());
            }

            t.setState({
                min: min,
                max: max,
                time_series: time_series
            })
        });
    }

    addStations() {
        const {messages} = this.props.i18n || {messages: {}};
        let t = this;

        try {
            t.state.map.removeLayer(t.state.stationsLayer)
        } catch (e) {
            console.log('no stations layer!')
        }

        let a = new Api('getstations/rome');
        a.Get(t.controller.signal).then(function (response) {
            return response.json();
        }).then(function (text) {
            try {
                parseString(text, function (err, result) {
                    let stations = [];
                    result.markers.stazione.forEach(function (item) {
                        let marker = new Feature({
                            geometry: new Point(
                                fromLonLat([Number(item['$']['longitudine']), Number(item['$']['latitudine'])])
                            ),
                            name: 'stations',
                            title: 'stations',
                            label: 'stations',
                            id: 'stations',
                            data_CET: item['$']['data_CET'],
                            dewp: item['$']['dewp'],
                            diff_hum_1dayago: item['$']['diff_hum_1dayago'],
                            diff_press_1dayago: item['$']['diff_press_1dayago'],
                            diff_temp_1dayago: item['$']['diff_temp_1dayago'],
                            hum: item['$']['hum'],
                            latitudine: item['$']['latitudine'],
                            link_dati: item['$']['link_dati'],
                            longitudine: item['$']['longitudine'],
                            nome: item['$']['nome'],
                            pioggiaday: item['$']['pioggiaday'],
                            pioggiamonth: item['$']['pioggiamonth'],
                            pioggiayear: item['$']['pioggiayear'],
                            press: item['$']['press'],
                            quota: item['$']['quota'],
                            raffica: item['$']['raffica'],
                            rafficamaxday: item['$']['rafficamaxday'],
                            rainrate: item['$']['rainrate'],
                            temp: item['$']['temp'],
                            tempmax: item['$']['tempmax'],
                            tempmin: item['$']['tempmin'],
                            timestamp: item['$']['timestamp'],
                            titolo: item['$']['titolo'],
                            vento: item['$']['vento'],
                            ventodir: item['$']['ventodir'],
                            webcam: item['$']['webcam'],
                            windchill: item['$']['windchill']
                        });
                        stations.push(marker);
                    });
                    let vectorSource = new VectorSource({
                        features: stations
                    });
                    let setStyle = function (feature) {

                        let fillClass0 = new Fill({color: [160, 160, 160, 0.7]});
                        let fillClass1 = new Fill({color: [94, 213, 255, 1]});
                        let fillClass2 = new Fill({color: [146, 209, 79, 1]});
                        let fillClass3 = new Fill({color: [255, 255, 0, 1]});
                        let fillClass4 = new Fill({color: [255, 165, 0, 1]});
                        let fillClass5 = new Fill({color: [252, 57, 3, 1]});
                        let fillClass6 = new Fill({color: [153, 1, 0, 1]});

                        let s = new Fill({color: [0, 0, 0, 0.5]});
                        let f = new Fill({color: '#000'});

                        let temp = Number(feature.get('temp'));

                        if (temp < 10) {
                            s = fillClass0
                        } else if (temp >= 0 && temp < 10) {
                            s = fillClass1
                        } else if (temp >= 10 && temp < 20) {
                            s = fillClass2
                        } else if (temp >= 20 && temp < 25) {
                            s = fillClass3
                        } else if (temp >= 25 && temp < 30) {
                            s = fillClass4;
                            f = new Fill({color: '#fff'})
                        } else if (temp >= 30 && temp < 35) {
                            s = fillClass5;
                            f = new Fill({color: '#fff'})
                        } else if (temp >= 35) {
                            s = fillClass6;
                            f = new Fill({color: '#fff'})
                        }
                        return new Style({
                            image: new CircleStyle({
                                fill: s,
                                radius: 16
                            }),
                            text: new Text({
                                textAlign: 'center',
                                textBaseline: 'middle',
                                font: '10px Calibri,sans-serif',
                                text: feature.get('temp').toString() + ' °C',
                                fill: f,
                                placement: 'point',
                                overflow: 'true'
                            })
                        });
                    };
                    let markerVectorLayer = new VectorLayer({
                        name: 'stations',
                        title: 'stations',
                        label: messages['stations'],
                        id: 'stations',
                        source: vectorSource,
                        style: setStyle
                    });

                    t.setState({
                        stationsLayer: markerVectorLayer
                    }, function () {
                        t.state.map.addLayer(markerVectorLayer);
                    });
                });
            } catch (e) {

            }
        });

    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        return (
            <div>
                <div id='map' className="map" style={{cursor: 'help'}}>
                    <pre id="info"/>
                </div>
                {
                    this.state.showSpinner ? <div id='map_mask'><PixelSpinner color="white"/></div> : ''
                }

                <TimeSlider
                    min={this.state.min}
                    max={this.state.max}
                    step={3600000}//10800000
                    map={this.state.map}
                />

                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{marginTop: '5em', minWidth: '25em', maxWidth: '100%', marginBottom: '5em'}}>
                    <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === '1'})}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <b><T>info</T></b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === '2'})}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <b><T>UTCI</T></b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === '3'})}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <b><T>Temperature</T></b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === '4'})}
                                        onClick={() => {
                                            this.toggleTab('4');
                                        }}
                                    >
                                        <b><T>RelHum</T></b>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <div>
                                                <Row>{this.state.tab1_0}</Row>
                                                {/*<hr/>*/}
                                                {/*<Table striped size="sm">*/}
                                                    {/*<tbody>*/}
                                                    {/*{this.state.tab1}*/}
                                                    {/*</tbody>*/}
                                                {/*</Table>*/}
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <Card body>
                                                <div style={{height: '25em'}}>
                                                    {
                                                        this.state.UTCI ? this.state.UTCI :
                                                            <PixelSpinner color="black"/>
                                                    }
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <Card body>
                                                <div style={{height: '25em'}}>
                                                    {
                                                        this.state.Temperature ? this.state.Temperature :
                                                            <PixelSpinner color="black"/>
                                                    }
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            <Card body>
                                                <div style={{height: '25em'}}>
                                                    {
                                                        this.state.RelHum ? this.state.RelHum :
                                                            <PixelSpinner color="black"/>
                                                    }
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.modalFooter}
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n
});

TheMapMobile = connect(mapStateToProps)(TheMapMobile);

export default TheMapMobile;