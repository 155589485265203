import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import MyMap from '../../components/map';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'
import ReactGA from "react-ga";


class Heraklion extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        ReactGA.pageview('/heraklion');
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/heraklion');
    // }

    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'AerialWithLabels'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLCDD',
                title: 'CDD',
                styles: [],
                legend: ['img/legends/cdd_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLDewTemperature',
                title: 'DewTemperature',
                styles: ['LIFEASTI:temp_annual_heraklion','LIFEASTI:temp_spring_heraklion','LIFEASTI:temp_summer_heraklion','LIFEASTI:temp_autumn_heraklion','LIFEASTI:temp_winter_heraklion'],
                legend: ['img/legends/temp_heraklion_annual.png','img/legends/temp_heraklion_spring.png','img/legends/temp_heraklion_summer.png','img/legends/temp_heraklion_autumn.png','img/legends/temp_heraklion_winter.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLDI',
                title: 'DI',
                styles: ['LIFEASTI:temp_annual_heraklion','LIFEASTI:temp_spring_heraklion','LIFEASTI:temp_summer_heraklion','LIFEASTI:temp_autumn_heraklion','LIFEASTI:temp_winter_heraklion'],
                legend: ['img/legends/di_heraklion_annual.png','img/legends/di_heraklion_spring.png','img/legends/di_heraklion_summer.png','img/legends/di_heraklion_autumn.png','img/legends/di_heraklion_winter.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLHDD',
                title: 'HDD',
                styles: [],
                legend: ['img/legends/hdd_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLLWUP',
                title: 'LWUP',
                styles: [],
                legend: ['img/legends/lwup_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLSWDOWN',
                title: 'SWDOWN',
                styles: [],
                legend: ['img/legends/swdown_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLTAPP',
                title: 'TAPP',
                styles: ['LIFEASTI:temp_annual_heraklion','LIFEASTI:temp_spring_heraklion','LIFEASTI:temp_summer_heraklion','LIFEASTI:temp_autumn_heraklion','LIFEASTI:temp_winter_heraklion'],
                legend: ['img/legends/tapp_heraklion_annual.png','img/legends/tapp_heraklion_spring.png','img/legends/tapp_heraklion_summer.png','img/legends/tapp_heraklion_autumn.png','img/legends/tapp_heraklion_winter.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLUHII',
                title: 'UHII',
                styles: [],
                legend: ['img/legends/uhi_int.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLCLDFRA',
                title: 'CLDFRA',
                styles: [],
                legend: ['img/legends/cld_fra_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLRelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLTemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_heraklion','LIFEASTI:temp_spring_heraklion','LIFEASTI:temp_summer_heraklion','LIFEASTI:temp_autumn_heraklion','LIFEASTI:temp_winter_heraklion'],
                legend: ['img/legends/temp_heraklion_annual.png','img/legends/temp_heraklion_spring.png','img/legends/temp_heraklion_summer.png','img/legends/temp_heraklion_autumn.png','img/legends/temp_heraklion_winter.png'],
                visible: true,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLWind',
                title: 'Wind',
                styles: ['LIFEASTI:windspeed2', 'LIFEASTI:wind_barbs', 'LIFEASTI:wind_arrows'],
                legend: ['img/legends/wind_spd_all.png', 'img/legends/wind_barbs.png', 'img/legends/wind_arrows.png'],
                visible: false,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'heraklion_border',
                title: 'border',
                styles: [],
                legend: [],
                visible: true,
                extent: [[23.1753525, 34.3771047], [26.5591415, 35.9220857]],
                opacity: 1,
                attributeTable: true
            }
        ];

        return (

            <MyMap
                projection={"EPSG:3857"}
                center={[25.1293739, 35.2796218]}
                zoom={12}
                minZoom={8}
                maxZoom={18}
                extent={[[23.1753525, 34.3771047], [26.5591415, 35.9220857]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

Heraklion = connect(mapStateToProps)(Heraklion);

export default Heraklion;
