import {Control} from 'ol/control';
import './addsources.css';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS";
import {Api} from "core/api";
import {transform} from "ol/proj";

let ol_custom_addsources = (function (Control) {

    let options;

    let element;

    let enableButton;

    let disableButton;

    let add_sources_div;

    let add_sources_input;

    let add_sources_select;

    let add_sources_get_layers_button;

    let add_sources_button;

    let layers;

    function AddSourcesCustomControl(opt_options) {
        options = opt_options || {};

        enableButton = document.createElement('button');
        enableButton.title = options['Add'] + ' WMS';
        enableButton.innerHTML = '<div style="font-size: 14px;">A</div>';

        disableButton = document.createElement('button');
        disableButton.title = options['Add'] + ' WMS';
        disableButton.innerHTML = '<div style="font-size: 14px;">X</div>';
        disableButton.style.display = "none";

        add_sources_div = document.createElement('div');
        add_sources_div.setAttribute("id", "add_sources_div");
        add_sources_div.style.display = "none";

        let add_sources_input_label = document.createElement("LABEL");
        add_sources_input_label.innerText = options['Add'] + ' WMS';
        add_sources_input = document.createElement("INPUT");
        add_sources_input.setAttribute("id", "add_sources_input");
        add_sources_input.setAttribute("type", "text");        

        add_sources_get_layers_button = document.createElement('button');
        add_sources_get_layers_button.setAttribute("id", "add_sources_get_layers_button");
        add_sources_get_layers_button.innerHTML = options['FindLayers'];

        let add_sources_select_label = document.createElement("LABEL");
        add_sources_select_label.innerText = options['SelectLayer'];
        add_sources_select = document.createElement("select");
        add_sources_select.setAttribute("id", "add_sources_select");

        add_sources_button = document.createElement('button');
        add_sources_button.setAttribute("id", "add_sources_button");
        add_sources_button.innerHTML = options['AddLayer'];

        element = document.createElement('div');
        element.className = 'ol-custom-add-sources ol-unselectable ol-control';
        element.appendChild(enableButton);
        element.appendChild(disableButton);
        add_sources_div.appendChild(add_sources_input_label);
        add_sources_div.appendChild(add_sources_input);
        add_sources_div.appendChild(add_sources_get_layers_button);
        add_sources_div.appendChild(add_sources_select_label);
        add_sources_div.appendChild(add_sources_select);
        add_sources_div.appendChild(add_sources_button);
        element.appendChild(add_sources_div);

        Control.call(this, {
            element: element,
            target: options.target
        });

        enableButton.addEventListener('click', this.enableAddSources.bind(this), false);
        disableButton.addEventListener('click', this.disableAddSources.bind(this), false);
        add_sources_get_layers_button.addEventListener('click', this.getLayersFromSources.bind(this), false);
        add_sources_button.addEventListener('click', this.addLayerFromSources.bind(this), false);
    }

    if (Control) AddSourcesCustomControl.__proto__ = Control;
    AddSourcesCustomControl.prototype = Object.create(Control && Control.prototype);
    AddSourcesCustomControl.prototype.constructor = AddSourcesCustomControl;

    AddSourcesCustomControl.prototype.enableAddSources = function doSwipe() {

        enableButton.style.display = "none";
        disableButton.style.display = "block";
        add_sources_div.style.display = "block";

        let map = this.getMap();

        const lys = map.getLayers().getArray();

        if(lys[1].values_.id === "THESSCDD"){
            add_sources_input.value = "https://gis.thessaloniki.gr/geoserver/wms";
        }
    };

    AddSourcesCustomControl.prototype.disableAddSources = function stopSwipe() {
        enableButton.style.display = "block";
        disableButton.style.display = "none";
        add_sources_div.style.display = "none";
    };

    AddSourcesCustomControl.prototype.getLayersFromSources = function getLayers() {
        let e0 = document.getElementById('add_sources_input');
        let source = e0.value;

        add_sources_select.innerHTML = "";

        let parser = new WMSCapabilities();
        let request = source + '&version=1.3.0&request=GetCapabilities';
        let a = new Api('geoserver/' + request);

        a.Get().then(function (response) {
            return response.json();
        }).then(function (text) {
            try {
                let result = parser.read(text);
                layers = result.Capability.Layer.Layer;
                layers.forEach(function (ly) {
                    let newListData = new Option(ly.Title, ly.Name);
                    add_sources_select.appendChild(newListData);
                });
            } catch (e) {

            }

        });
    };

    AddSourcesCustomControl.prototype.addLayerFromSources = function addLayer() {

        try {
            let map = this.getMap();
            let projectionCode = map.getView().getProjection().getCode()

            let e0 = document.getElementById('add_sources_input');
            let source = e0.value;

            let e1 = document.getElementById("add_sources_select");
            let layer_name = e1.options[e1.selectedIndex].value;

            let lyr = null;
            layers.forEach(function (ly) {
                if (ly.Name === layer_name) {
                    lyr = ly;
                }
            });

            let styles = [];
            try {
                lyr.Style.forEach(function (style) {
                    styles.push(style.Name)
                })
            } catch (e) {

            }

            let layer_new_1 = new ImageLayer({
                id: layer_name,
                title: layer_name,
                baseLayer: false,
                swipe: false,
                visible: true,
                styles: styles,
                // extent: transform([lyr.EX_GeographicBoundingBox[0], lyr.EX_GeographicBoundingBox[1]], 'EPSG:4326', projectionCode).concat(transform([lyr.EX_GeographicBoundingBox[2], lyr.EX_GeographicBoundingBox[3]], 'EPSG:4326', projectionCode)),
                source: new ImageWMS({
                    url: source,
                    params: {
                        LAYERS: layer_name,
                        TILED: false
                    },
                    serverType: 'geoserver',
                    transition: 0
                }),
                opacity: 1,
                attributeTable: false
            });

            map.addLayer(layer_new_1);

        } catch (e) {

        }
    };

    return AddSourcesCustomControl;
}(Control));

export default ol_custom_addsources
