import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import TheMapMobile from '../../components/mapMobile';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'


class Rome extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Aerial'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMERelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[12.0771636, 41.5765105], [13.0933989, 42.1736755]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMEUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[12.0771636, 41.5765105], [13.0933989, 42.1736755]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'ROMETemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_rome','LIFEASTI:temp_spring_rome','LIFEASTI:temp_summer_rome','LIFEASTI:temp_autumn_rome','LIFEASTI:temp_winter_rome'],
                legend: ['img/legends/temp_rome_annual.png','img/legends/temp_rome_spring.png','img/legends/temp_rome_summer.png','img/legends/temp_rome_autumn.png','img/legends/temp_rome_winter.png'],
                visible: true,
                extent: [[12.0771636, 41.5765105], [13.0933989, 42.1736755]],
                opacity: 0.6,
                attributeTable: true
            }
        ];

        return (

            <TheMapMobile
                romeStationsURL={"https://www.meteoregionelazio.it/asti-network/staz_mrl.xml"}
                projection={"EPSG:3857"}
                center={[12.4905242, 41.9023711]}
                zoom={12}
                minZoom={10}
                maxZoom={18}
                extent={[[12.0771636, 41.5765105], [13.0933989, 42.1736755]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

Rome = connect(mapStateToProps)(Rome);

export default Rome;
