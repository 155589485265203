import React, { Component } from 'react';
import { Input as ReactstrapInput } from 'reactstrap';

class Input extends Component {

	constructor(props) {
		super(props);
		this.state = {
			valid: undefined,
			invalid: undefined
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({value: this.props.value});
		this.validate(this.props.value, this.props.pattern);
	}

	handleChange(event) {
		if (typeof this.props.onChange === 'function')
			this.props.onChange(event);
		this.setState({value: event.target.value});
		this.validate(event.target.value, this.props.pattern);
	}

	validate(value, pattern) {
		if (!pattern)
			return;

		let regex = new RegExp('^' + pattern + '$');
		const valid = value.trim() === '' ? undefined : regex.test(value);
		const invalid = value.trim() === '' ? undefined : !valid;
		this.setState({
			valid,
			invalid
		});
	}

	render() {

		const { pattern, value, onChange, ...otherProps } = this.props;

		return (
			<ReactstrapInput
				{...otherProps}
				value={this.props.value}
				valid={this.state.valid}
				invalid={this.state.invalid}
				onChange={this.handleChange}
			/>
		);
	}
}

export default Input;
