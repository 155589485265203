import React, { Component } from 'react';
import {
    Collapse,
    TabPane,
    Row,
    Col,
    Card,
    CardBody, CardTitle, CardText, Button
} from 'reactstrap';
import UTCI from '../UTCI/UTCI';
import T from 'modules/i18n';
import moment from 'moment';
import ChartLine from '../chartlline/chartlline';
import { Loading } from 'core/components'
import './dashboardTabPane.css';
import MiniMap from '../../components/minimap/minmap';
import * as roles from 'core/model/roles';

import utci from './legends/utci.png';
import temperature from './legends/temperature.png';
import tapp from './legends/tapp.png';
import humidity from './legends/humidity.png';

import logo from '../img/logo.png';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

class DashboardTabPane extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapUTCI: false,
            mapTemp: false,
            mapTapp: false,
            mapHum: false,
            chartUTCI: false,
            chartTemp: false,
            chartHum: false,
            modal: false,
            region: null
        };
        this.toggle = this.toggle.bind(this);
        this.getWidget = this.getWidget.bind(this);
    }

    toggle() {

        if (this.props.theData) {

            this.setState(prevState => ({
                modal: !prevState.modal
            }));

            let categories = [];

            let dataUTCIavg = [];
            let dataUTCImin = [];
            let dataUTCImax = [];

            let dataTempavg = [];
            let dataTempmin = [];
            let dataTempmax = [];

            let dataTappavg = [];
            let dataTappmin = [];
            let dataTappmax = [];

            let dataHumavg = [];
            let dataHummin = [];
            let dataHummax = [];

            this.props.theData.all_data.forEach(function (item) {
                categories.push(moment(item.date_time));

                dataUTCIavg.push(item.utci_avg.toFixed(0));
                dataUTCImin.push(item.utci_min.toFixed(0));
                dataUTCImax.push(item.utci_max.toFixed(0));

                dataTempavg.push(item.temp_avg.toFixed(1));
                dataTempmin.push(item.temp_min.toFixed(1));
                dataTempmax.push(item.temp_max.toFixed(1));

                dataTappavg.push(item.tapp_avg.toFixed(1));
                dataTappmin.push(item.tapp_min.toFixed(1));
                dataTappmax.push(item.tapp_max.toFixed(1));

                dataHumavg.push(item.hum_avg.toFixed(0));
                dataHummin.push(item.hum_min.toFixed(0));
                dataHummax.push(item.hum_max.toFixed(0));
            });

            let seriesUTCI = [{
                name: 'min',
                data: dataUTCImin
            }, {
                name: 'avg',
                data: dataUTCIavg
            }, {
                name: 'max',
                data: dataUTCImax
            }];

            let seriesTemperature = [{
                name: 'min',
                data: dataTempmin
            }, {
                name: 'avg',
                data: dataTempavg
            }, {
                name: 'max',
                data: dataTempmax
            }];

            let seriesTapp = [{
                name: 'min',
                data: dataTappmin
            }, {
                name: 'avg',
                data: dataTappavg
            }, {
                name: 'max',
                data: dataTappmax
            }];

            let seriesRelHum = [{
                name: 'min',
                data: dataHummin
            }, {
                name: 'avg',
                data: dataHumavg
            }, {
                name: 'max',
                data: dataHummax
            }];

            let chrtUTCI = <ChartLine region={this.props.region} title={this.props.messages['UTCI']} categories={categories} series={seriesUTCI}
                yaxisTitle={'UTCIc'}
                xaxisTitle={'date'} />;
            let chrtTemp = <ChartLine region={this.props.region} title={this.props.messages['Temperature']} categories={categories} series={seriesTemperature}
                yaxisTitle={'Temperature'}
                xaxisTitle={'date'} />;
            let chrtTapp = <ChartLine region={this.props.region} title={this.props.messages['TAPP']} categories={categories} series={seriesTapp}
                yaxisTitle={'TAPP'}
                xaxisTitle={'date'} />;
            let chrtHum = <ChartLine region={this.props.region} title={this.props.messages['RelHum']} categories={categories} series={seriesRelHum}
                yaxisTitle={'RelHum'}
                xaxisTitle={'date'} />;

            this.setState({

                chartUTCI: chrtUTCI,
                chartTemp: chrtTemp,
                chartTapp: chrtTapp,
                chartHum: chrtHum,
            }, function () {
                this.setState({
                    mapUTCI: <React.Fragment><MiniMap id={"UTCI_" + this.props.tabId} city={this.props.city}
                        region={this.props.region} /><img
                            src={utci} alt={'legend'} style={{ position: 'absolute', top: '4.5em', right: '0.5em' }} />
                    </React.Fragment>,
                    mapTemp: <React.Fragment><MiniMap id={"Temperature_" + this.props.tabId} city={this.props.city}
                        region={this.props.region} /><img
                            src={temperature} alt={'legend'} style={{ position: 'absolute', top: '4.5em', right: '0.5em' }} />
                    </React.Fragment>,
                    mapTapp: <React.Fragment><MiniMap id={"TAPP_" + this.props.tabId} city={this.props.city}
                        region={this.props.region} /><img
                            src={tapp} alt={'legend'} style={{ position: 'absolute', top: '4.5em', right: '0.5em' }} />
                    </React.Fragment>,
                    mapHum: <React.Fragment>
                        <MiniMap id={"RelHum_" + this.props.tabId} city={this.props.city} region={this.props.region} />
                        <img src={humidity} alt={'legend'}
                            style={{ position: 'absolute', top: '4.5em', right: '0.5em' }} />
                    </React.Fragment>
                })
            })


        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.region !== this.props.region) {
            this.setState({
                region: this.props.region
            }, () => {
                this.toggle();
                this.toggle();
            })

        }
    }

    getWidget() {
        let t = this;
        document.getElementById(this.props.tabId + 'forPrint').style.display = "block";
        htmlToImage.toPng(document.getElementById(t.props.tabId))
            .then(function (dataUrl) {
                download(dataUrl, 'lifeasti.png');
                document.getElementById(t.props.tabId + 'forPrint').style.display = "none";
            });
    }

    render() {

        let tapp = 'whitesmoke';
        let tappHeader = '';


        if (this.props.francesca === 0) {
            tapp = '#00C000';
            tappHeader = 'UTCI5';
        } else if (this.props.francesca === 1) {
            tapp = 'yellow';
            tappHeader = 'UTCI6';
        } else if (this.props.francesca === 2) {
            tapp = 'orange';
            tappHeader = 'UTCI7';
        } else if (this.props.francesca === 3) {
            tapp = 'red';
            tappHeader = 'UTCI8';
        }

        // if (this.props.tapp === null || this.props.tapp < -40) {
        //     tappHeader = '';
        // } else if (this.props.tapp >= -40 && this.props.tapp < -27) {
        //     tappHeader = 'UTCI1';
        // } else if (this.props.tapp >= -27 && this.props.tapp < -13) {
        //     tappHeader = 'UTCI2';
        // } else if (this.props.tapp >= -13 && this.props.tapp < 0) {
        //     tappHeader = 'UTCI3';
        // } else if (this.props.tapp >= 0 && this.props.tapp < 9) {
        //     tappHeader = 'UTCI4';
        // } else if (this.props.tapp >= 9 && this.props.tapp < 26) {
        //     tappHeader = 'UTCI5';
        // } else if (this.props.tapp >= 26 && this.props.tapp < 32) {
        //     tappHeader = 'UTCI6';
        // } else if (this.props.tapp >= 32 && this.props.tapp < 38) {
        //     tappHeader = 'UTCI7';
        // } else if (this.props.tapp >= 38 && this.props.tapp < 46) {
        //     tappHeader = 'UTCI8';
        // } else {
        //     tappHeader = '';
        // }

        return (
            <TabPane tabId={this.props.tabId}>
                <Row>

                    <Col sm="12">

                        <Row>
                            <Col xs="12" sm="6">
                                <Card>
                                    <CardBody>
                                        <img src={this.props.img} width={'100%'} alt={this.props.img} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="6">

                                <div id={this.props.tabId}>
                                    <h4 id={this.props.tabId + 'forPrint'} style={{ display: 'none' }}>
                                        <img src={logo} width={'20%'} style={{ paddingRight: '20px' }} alt={logo} />
                                        {this.props.messages[this.props.city]}: {this.props.messages[this.props.region]}
                                        {this.props.theData ? ' (' + this.props.theData.date + ')' : ''}
                                    </h4>
                                    <Card style={{ position: 'relative' }}>
                                        <CardBody>
                                            <CardTitle>
                                                <h4><T>HeatHealthWarningSystem</T></h4>
                                            </CardTitle>
                                            <CardText />
                                            <Row>
                                                <Col sm="4">
                                                    <div className={'utci-div'}
                                                        style={{ margin: '10px', backgroundColor: tapp }}>
                                                        <div className="utci-div-value">
                                                            {this.props.tapp}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="8" className={"dashboardTabPane-vertical-center"}>
                                                    <h5 style={{ textAlign: "left" }}><T>TAPP</T> - <T>dailyMaxAverage</T></h5>
                                                    <T>TAPPDescription</T>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6><T>mortality</T></h6>
                                                    <div>
                                                        <Row style={{ marginLeft: "5px", marginTop: "-20px" }}>
                                                            <Col>
                                                                <Row>
                                                                    <div style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#00C000",
                                                                        display: "inline-block",
                                                                        width: "20px",
                                                                        height: "20px"
                                                                    }}>&ensp;</div>
                                                                    &ensp;<div style={{ fontSize: "smaller" }}><T>UTCI5</T></div>
                                                                </Row>
                                                                <Row>
                                                                    <div style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "yellow",
                                                                        display: "inline-block",
                                                                        width: "20px",
                                                                        height: "20px"
                                                                    }}>&ensp;</div>
                                                                    &ensp;<div style={{ fontSize: "smaller" }}><T>UTCI6</T></div>
                                                                </Row>
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <div style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "orange",
                                                                        display: "inline-block",
                                                                        width: "20px",
                                                                        height: "20px"
                                                                    }}>&ensp;</div>
                                                                    &ensp;<div style={{ fontSize: "smaller" }}><T>UTCI7</T></div></Row>
                                                                <Row>
                                                                    <div style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "red",
                                                                        display: "inline-block",
                                                                        width: "20px",
                                                                        height: "20px"
                                                                    }}>&ensp;</div>
                                                                    &ensp;<div style={{ fontSize: "smaller" }}><T>UTCI8</T></div></Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" sm="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle><h4><T>Temperature</T> - <T>dailyAverage</T></h4></CardTitle>
                                        <CardText className={'dashboardTabPane-horizontal-center'}>
                                            {this.props.temperature}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle><h4><T>RelHum</T> - <T>dailyAverage</T></h4></CardTitle>
                                        <CardText className={'dashboardTabPane-horizontal-center'}>
                                            {this.props.humidity}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {
                            this.props.city !== 'rome' ?

                                <Row>
                                    <Col xs="12" sm="6">
                                        <UTCI
                                            title={this.props.UTCI_title}
                                            value={this.props.UTCI_value}
                                            unit={this.props.UTCI_unit}
                                            fixed={this.props.UTCI_fixed}
                                        />
                                    </Col>
                                </Row>

                                : ''
                        }

                        <Row>
                            {this.props.francesca != null && this.props.role === roles.AUTHORIZED ?
                                <Col xs="12" sm="6">
                                    <Card>
                                        <CardBody
                                            style={{ backgroundColor: this.props.francesca === 0 ? '#00C000' : this.props.francesca === 1 ? 'yellow' : this.props.francesca === 2 ? 'orange' : 'red' }}>
                                            <CardTitle><h4
                                                style={{ color: this.props.francesca === 1 ? '#EC9E20' : this.props.francesca === 2 ? 'black' : 'black' }}>
                                                <T>mortality</T></h4></CardTitle>
                                            <CardText className={'dashboardTabPane-horizontal-center'}
                                                style={{ inlineSize: 'fit-content' }}>

                                                {this.props.francesca === 0 ?
                                                    <T>AlertLevel0</T> : this.props.francesca === 1 ?
                                                        <T>AlertLevel1</T> : this.props.francesca === 2 ?
                                                            <T>AlertLevel2</T> : <T>AlertLevel3</T>}

                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                :
                                ''
                            }

                        </Row>

                    </Col>

                </Row>

                <Row>
                    <Col sm="12">
                        <Button color="primary" style={{ width: '100%' }}
                            onClick={this.toggle}><T>expertPanel</T></Button>{' '}
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Collapse isOpen={this.state.modal}>

                            <Card>
                                <CardBody>
                                    <CardTitle><h4><T>TAPP</T></h4></CardTitle>
                                    <div style={{ height: '550px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertMapDescription3</T><T>ExpertMapDescription2_tapp</T><T>ExpertMapDescription4</T>
                                        </p>
                                        <h6 style={{
                                            position: 'absolute',
                                            zIndex: 800,
                                            top: '4.5em',
                                            left: '0.5em',
                                            color: 'white'
                                        }}><T>maxValues</T></h6>
                                        {this.state.mapTapp ? this.state.mapTapp : <Loading />}
                                    </div>
                                    <div style={{ height: '400px', marginTop: '20px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertChartDescription1</T><T>ExpertMapDescription2_tapp</T>. <T>ExpertChartDescription2</T>
                                        </p>
                                        {this.state.chartTapp ? this.state.chartTapp : <Loading />}
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle><h4><T>Temperature</T></h4></CardTitle>
                                    <div style={{ height: '550px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertMapDescription3</T><T>ExpertMapDescription2_temp</T><T>ExpertMapDescription4</T>
                                        </p>
                                        <h6 style={{
                                            position: 'absolute',
                                            zIndex: 800,
                                            top: '4.5em',
                                            left: '0.5em',
                                            color: 'white'
                                        }}><T>maxValues</T></h6>
                                        {this.state.mapTemp ? this.state.mapTemp : <Loading />}
                                    </div>
                                    <div style={{ height: '400px', marginTop: '20px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertChartDescription1</T><T>ExpertMapDescription2_temp</T>. <T>ExpertChartDescription2</T>
                                        </p>
                                        {this.state.chartTemp ? this.state.chartTemp : <Loading />}
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle><h4><T>RelHum</T></h4></CardTitle>
                                    <div style={{ height: '550px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertMapDescription3</T><T>ExpertMapDescription2_hum</T><T>ExpertMapDescription4</T>
                                        </p>
                                        <h6 style={{
                                            position: 'absolute',
                                            zIndex: 800,
                                            top: '4.5em',
                                            left: '0.5em',
                                            color: 'white'
                                        }}><T>maxValues</T></h6>
                                        {this.state.mapHum ? this.state.mapHum : <Loading />}
                                    </div>
                                    <div style={{ height: '400px', marginTop: '20px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertChartDescription1</T><T>ExpertMapDescription2_hum</T>. <T>ExpertChartDescription2</T>
                                        </p>
                                        {this.state.chartHum ? this.state.chartHum : <Loading />}
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle><h4><T>UTCI</T></h4></CardTitle>
                                    <div style={{ height: '550px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertMapDescription3</T><T>ExpertMapDescription2_utci</T><T>ExpertMapDescription4</T>
                                        </p>
                                        <h6 style={{
                                            position: 'absolute',
                                            zIndex: 800,
                                            top: '4.5em',
                                            left: '0.5em',
                                            color: 'white'
                                        }}><T>maxValues</T></h6>
                                        {this.state.mapUTCI ? this.state.mapUTCI : <Loading />}
                                    </div>
                                    <div style={{ height: '400px', marginTop: '20px', position: 'relative' }}>
                                        <p>
                                            <T>ExpertChartDescription1</T><T>ExpertMapDescription2_utci</T>. <T>ExpertChartDescription2</T>
                                        </p>
                                        {this.state.chartUTCI ? this.state.chartUTCI : <Loading />}
                                    </div>
                                </CardBody>
                            </Card>

                        </Collapse>
                    </Col>
                </Row>

            </TabPane>
        );
    }
}

export default DashboardTabPane;
