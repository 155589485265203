import * as roles from '../model/roles';

const Admin = '/admin';
// const PassRecovery = '/password_recovery';

export const Pages = {
	Admin
};

/**
 * Static routes
 */

// Admin
const AdminDashboard = '/admin/dashboard';
// const Dashboard = '/dashboard';
const Profile = '/profile';
const Root = '/';

export const StaticRoutes = {
	AdminDashboard,
	// Dashboard,
	Profile,
	Root
};

export const DynamicRoutes = {};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = [
	{
		url: Root,
		name: 'home',
		component: 'Front',
		exact: true,
		role: [roles.GUEST, roles.AUTHORIZED, roles.REVIEWER]
	},
	{
		url: Root,
		name: 'home',
		to: AdminDashboard,
		exact: true,
		role: roles.ADMIN
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.ADMIN
	},
	// {
	// 	url: Dashboard,
	// 	name: 'dashboard',
	// 	component: 'Dashboard'
	// },
	{
		url: Profile,
		name: 'profile',
		component: 'Profile',
		role: [roles.AUTHORIZED, roles.REVIEWER]
	}
];

export default routes;
