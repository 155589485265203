import {Control} from 'ol/control';
import './print.css';

let ol_custom_print = (function (Control) {

    let options;

    let element;

    let enableButton;

    function AddPrintCustomControl(opt_options) {
        options = opt_options || {};

        enableButton = document.createElement('button');
        enableButton.title = 'Print';
        enableButton.innerHTML = '<div style="font-size: 14px;">P</div>';

        element = document.createElement('div');
        element.className = 'ol-custom-print ol-unselectable ol-control';
        element.appendChild(enableButton);


        Control.call(this, {
            element: element,
            target: options.target
        });

        enableButton.addEventListener('click', this.print.bind(this), false);
    }

    if (Control) AddPrintCustomControl.__proto__ = Control;
    AddPrintCustomControl.prototype = Object.create(Control && Control.prototype);
    AddPrintCustomControl.prototype.constructor = AddPrintCustomControl;

    AddPrintCustomControl.prototype.print = function print() {

        const map = this.getMap();

        const layers = map.getLayers().getArray();

        let dly = [];

        dly.push(
            {
                baseURL: 'http://a.tile.openstreetmap.org',
                singleTile: false,
                type: 'OSM',
                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                tileSize: [256, 256],
                extension: 'png',
                resolutions: [156543.0339, 78271.51695, 39135.758475, 19567.8792375, 9783.93961875, 4891.969809375, 2445.9849046875, 1222.99245234375, 611.496226171875, 305.7481130859375, 152.87405654296876, 76.43702827148438, 38.21851413574219, 19.109257067871095, 9.554628533935547, 4.777314266967774, 2.388657133483887, 1.1943285667419434, 0.5971642833709717]
            }
        );

        layers.forEach(function (layer) {
            if (!layer.get('baseLayer') && layer.get('visible')) {
                try{
                    dly.push(
                        {
                            baseURL: layer.get('source').url_ + '?time=' + layer.get('source').params_.TIME,
                            singleTile: true,
                            layers: [layer.get('id')],
                            time: layer.get('source').params_.TIME,
                            title: layer.get('title'),
                            legend: layer.get('legend')[0],
                            opacity: 0.7,
                            type: 'WMS',
                            format: 'image/png'
                        }
                    );
                }catch (e) {}
            }
        });

        const dta = {
            units: 'meters',
            srs: 'EPSG:900913',
            geodetic: true,
            layout: 'lifeasti',
            dpi: '150',
            mapTitle: options[dly[dly.length - 2].title],
            legendImage: 'https://app.lifeasti.eu/' + dly[dly.length - 2].legend,
            comment: 'lifeasti',
            resourcesUrl: 'http://localhost:8081/img',
            layers: dly,
            pages: [{
                center: [map.getView().getCenter()[0], map.getView().getCenter()[1]],
                geodetic: true,
                strictEpsg4326: true,
                scale: '50000',
                rotation: 0
            }]
        };

        (async () => {
            const rawResponse = await fetch('/api/geoserverprint', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dta)
            });
            const content = await rawResponse.json();

            window.open(JSON.parse(content).getURL.replace('http://localhost:8081', 'https://app.lifeasti.eu'));

        })();


    };

    return AddPrintCustomControl;
}(Control));

export default ol_custom_print
