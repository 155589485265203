import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeLocale} from '../../ducks/i18n';

class LangIconMenu extends Component {

    render() {
        const {dispatch} = this.props;
        return (
            <>
                <li onClick={() => dispatch(changeLocale('el'))}><i className="flag-icon flag-icon-gr"
                                                                    style={{margin: '0.3em', cursor: "pointer"}}/></li>
                <li onClick={() => dispatch(changeLocale('it'))}><i className="flag-icon flag-icon-it"
                                                                    style={{margin: '0.3em', cursor: "pointer"}}/></li>
                <li onClick={() => dispatch(changeLocale('en'))}><i className="flag-icon flag-icon-gb"
                                                                    style={{margin: '0.3em', cursor: "pointer"}}/></li>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.i18n.locale,
});

LangIconMenu = connect(mapStateToProps)(LangIconMenu);

export default LangIconMenu;
