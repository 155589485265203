import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Row,
	Col,
	CardGroup,
	Card,
	CardBody,
	CardFooter,
	Button,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Form,
} from 'reactstrap';

import { postData as submit } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import T from 'modules/i18n';

class PassRecovery extends Component {

	constructor(props) {
		super(props);
		this.state = {username: '', sent: false};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		let target = event.target;
		this.setState({
			...this.state,
			[target.name]: target.value
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch } = this.props;
		if (this.state.username !== '')
			dispatch(submit('recovery', this.state, false));
	}

	componentWillReceiveProps(nextProps) {
		const { dispatch, post_status } = this.props;
		let body, type;
		if (!nextProps.props_pending && post_status !== nextProps.post_status) {
			switch (nextProps.post_status) {
				case 404:
					body = 'user not found';
					type = 'error';
					break;
				case 200:
					body = 'you will soon receive your email';
					type = 'success';
					this.setState({sent: true});
					break;
				default:
					body = 'unknown error';
					type = 'error';
			}
			dispatch(pushNotification({ body, type }));
		}
	}

	render() {
		let message = this.props.notifications.pop();
		let disabled = this.state.sent ? {disabled: 'disabled'} : {};

		return (
			<div className="app-container flex-row align-items-center">
				<Container>
					<Form onSubmit={this.handleSubmit}>
						<fieldset {...disabled}>
							<Row className="justify-content-center">
								<Col sm="12" md="8" lg="6">
									<CardGroup>
										<Card className="text-muted" >
											<CardBody className="text-center">
												<div>
													<h2><T>recover your password</T></h2>
													<p><T>password_recovery_details</T></p>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="icon-user"></i>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															placeholder={this.props.i18n.username}
															value={this.state.username}
															onChange={this.handleChange}
															name="username"
															required
														/>
													</InputGroup>
												</div>
											</CardBody>
											<CardFooter className="">
												<Row>
													<Col xs="9" md="8" lg="8">
														{ message &&
															<span className={message.type}>
																<T>{message.body}</T>
															</span>
														}
													</Col>
													<Col xs="3" md="4" lg="4" className="text-right	">
														<Button color="success" className="mt-3" active>
															<T>send email</T>
														</Button>
													</Col>
												</Row>
											</CardFooter>
										</Card>
									</CardGroup>
								</Col>
							</Row>
						</fieldset>
					</Form>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages,
	notifications: state.notifications.messages,
	post_pending: state.update.sending,
	post_status: state.update.status
});

PassRecovery = connect(mapStateToProps)(PassRecovery);

export default PassRecovery;