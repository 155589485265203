import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import MyMap from '../../components/map';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'
import ReactGA from "react-ga";


class PavlosMelas extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        ReactGA.pageview('/pavlosmelas');
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     ReactGA.pageview('/pavlosmelas');
    // }

    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'AerialWithLabels'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSCDD',
                title: 'CDD',
                styles: [],
                legend: ['img/legends/cdd_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSDewTemperature',
                title: 'DewTemperature',
                styles: ['LIFEASTI:temp_annual_thess','LIFEASTI:temp_spring_thess','LIFEASTI:temp_summer_thess','LIFEASTI:temp_autumn_thess','LIFEASTI:temp_winter_thess'],
                legend: ['img/legends/temp_thessaloniki_annual.png','img/legends/temp_thessaloniki_spring.png','img/legends/temp_thessaloniki_summer.png','img/legends/temp_thessaloniki_autumn.png','img/legends/temp_thessaloniki_winter.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSDI',
                title: 'DI',
                styles: ['LIFEASTI:temp_annual_thess','LIFEASTI:temp_spring_thess','LIFEASTI:temp_summer_thess','LIFEASTI:temp_autumn_thess','LIFEASTI:temp_winter_thess'],
                legend: ['img/legends/di_thessaloniki_annual.png','img/legends/di_thessaloniki_spring.png','img/legends/di_thessaloniki_summer.png','img/legends/di_thessaloniki_autumn.png','img/legends/di_thessaloniki_winter.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSHDD',
                title: 'HDD',
                styles: [],
                legend: ['img/legends/hdd_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSLWUP',
                title: 'LWUP',
                styles: [],
                legend: ['img/legends/lwup_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSSWDOWN',
                title: 'SWDOWN',
                styles: [],
                legend: ['img/legends/swdown_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSTAPP',
                title: 'TAPP',
                styles: ['LIFEASTI:temp_annual_thess','LIFEASTI:temp_spring_thess','LIFEASTI:temp_summer_thess','LIFEASTI:temp_autumn_thess','LIFEASTI:temp_winter_thess'],
                legend: ['img/legends/tapp_thessaloniki_annual.png','img/legends/tapp_thessaloniki_spring.png','img/legends/tapp_thessaloniki_summer.png','img/legends/tapp_thessaloniki_autumn.png','img/legends/tapp_thessaloniki_winter.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSUHII',
                title: 'UHII',
                styles: [],
                legend: ['img/legends/uhi_int.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSCLDFRA',
                title: 'CLDFRA',
                styles: [],
                legend: ['img/legends/cld_fra_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSRelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSTemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_thess','LIFEASTI:temp_spring_thess','LIFEASTI:temp_summer_thess','LIFEASTI:temp_autumn_thess','LIFEASTI:temp_winter_thess'],
                legend: ['img/legends/temp_thessaloniki_annual.png','img/legends/temp_thessaloniki_spring.png','img/legends/temp_thessaloniki_summer.png','img/legends/temp_thessaloniki_autumn.png','img/legends/temp_thessaloniki_winter.png'],
                visible: true,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'THESSWind',
                title: 'Wind',
                styles: ['LIFEASTI:windspeed2', 'LIFEASTI:wind_barbs', 'LIFEASTI:wind_arrows'],
                legend: ['img/legends/wind_spd_all.png', 'img/legends/wind_barbs.png', 'img/legends/wind_arrows.png'],
                visible: false,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 0.7,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'thessaloniki_border',
                title: 'border',
                styles: [],
                legend: [],
                visible: true,
                extent: [[21.2033066, 39.6645956], [24.9551376, 41.8733435]],
                opacity: 1,
                attributeTable: true
            }
        ];

        return (

            <MyMap
                projection={"EPSG:3857"}
                center={[22.9485878, 40.68]}
                zoom={13}
                minZoom={8}
                maxZoom={18}
                extent={[[21.2033066, 39.6645956], [24.9551376, 41.8733435]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n
});

PavlosMelas = connect(mapStateToProps)(PavlosMelas);

export default PavlosMelas;
