import React, {Component} from 'react';
import {connect} from 'react-redux';

import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';

import TheMapMobile from '../../components/mapMobile';
import GroupLayer from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";

import './css/map.css'


class Heraklion extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        const basemapLayers = new GroupLayer({
            title: 'Basemaps', swipe: false, openInLayerSwitcher: false, baseLayer: false, attributeTable: false, layers: [
                new TileLayer({
                    id: 'Aerial',
                    title: 'Aerial',
                    baseLayer: true,
                    attributeTable: false,
                    swipe: false,
                    visible: true,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Aerial'
                    })
                }),
                new TileLayer({
                    id: 'Road',
                    title: 'Road',
                    baseLayer: true,
                    swipe: false,
                    attributeTable: false,
                    visible: false,
                    source: new BingMaps({
                        key: 'AmpGtLILsoABZuJkTkitiID8fambYi8nyhzAynPMael57_yQ0kIFnZVdXLa8eVAN',
                        imagerySet: 'Road'
                    })
                })
            ]
        });

        const mapLayers = [
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLRelHum',
                title: 'RelHum',
                styles: [],
                legend: ['img/legends/rel_hum_all.png'],
                visible: false,
                extent: [[24.8794350, 35.1421736], [25.4246315, 35.4006076]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLUTCI',
                title: 'UTCI',
                styles: [],
                legend: ['img/legends/utci_all.png'],
                visible: false,
                extent: [[24.8794350, 35.1421736], [25.4246315, 35.4006076]],
                opacity: 0.6,
                attributeTable: true
            },
            {
                type: 'ImageLayer',
                url: 'https://app.lifeasti.eu/geoserver/LIFEASTI/wms',
                name: 'HERAKLTemperature',
                title: 'Temperature',
                styles: ['LIFEASTI:temp_annual_heraklion','LIFEASTI:temp_spring_heraklion','LIFEASTI:temp_summer_heraklion','LIFEASTI:temp_autumn_heraklion','LIFEASTI:temp_winter_heraklion'],
                legend: ['img/legends/temp_heraklion_annual.png','img/legends/temp_heraklion_spring.png','img/legends/temp_heraklion_summer.png','img/legends/temp_heraklion_autumn.png','img/legends/temp_heraklion_winter.png'],
                visible: true,
                extent: [[24.8794350, 35.1421736], [25.4246315, 35.4006076]],
                opacity: 0.6,
                attributeTable: true
            }
        ];

        return (

            <TheMapMobile
                projection={"EPSG:3857"}
                center={[25.1293739, 35.2796218]}
                zoom={12}
                minZoom={10}
                maxZoom={18}
                extent={[[24.8794350, 35.1421736], [25.4246315, 35.4006076]]}
                basemapLayers={basemapLayers}
                mapLayers={mapLayers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages
});

Heraklion = connect(mapStateToProps)(Heraklion);

export default Heraklion;
